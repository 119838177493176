<script setup>
import { ref } from 'vue';
import { loadGridScoreDerivationMeasurement } from '@/composables/charts';

import CameraBaseChart from '@/components/charts/CameraBaseChart.vue';

const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregateCameras: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    chainTimeSetup: { type: Boolean, default: false },
    defaultCollapsed: { type: Boolean, default: false },
  },
)
const timeSetup = defineModel('timeSetup', { required: true, type: Object })
const options = ref({})


async function dataLoader(cameraIds, timeSetup) {
  const data = await loadGridScoreDerivationMeasurement({
    timeResolution: timeSetup.timeResolution,
    aggregateCameras: props.aggregateCameras,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    cameraIds: cameraIds,
    chartType: 'line',
  })
  return data
}

</script>

<template>
  <CameraBaseChart
    v-model:time-setup="timeSetup"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :default-collapsed="props.defaultCollapsed"
    :options="options"
    :title="props.title"
    :subtitle="props.subtitle"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
