<script setup>
import { computed, onMounted } from 'vue';
import { useCamerasStore } from '@/store/cameras';
import BaseChart from '@/components/charts/BaseChart.vue';

const props = defineProps({
  height: { type: Number, default: 400 },
  options: { type: Object, default: () => {} },
  cameraIds: { type: Array, default: () => [] },
  chartType: { type: String, default: 'line' },
  dataLoader: {
    type: Function, default: async function () {
    },
  },
  title: { type: String, default: '' },
  subtitle: { type: String, default: '' },
  disableControlRow: { type: Boolean, default: false },
  hideTimeResolution: { type: Boolean, default: false },
  chainTimeSetup: { type: Boolean, default: false },
  defaultCollapsed: { type: Boolean, default: false },
})
const timeSetup = defineModel('timeSetup', { required: true, type: Object })

const localCameraIds = computed(() => {
  if (props.cameraIds.length > 0) return props.cameraIds
  if(!cameras.value) return []
  return Object.values(cameras.value).map(({ cameraId }) => cameraId)
})

async function localDataLoader(timeSetup) {
  return await props.dataLoader(localCameraIds.value, timeSetup)
}

const cameras = computed(() => useCamerasStore().getNonDecommissionedCameras())
const camerasLoaded = computed(() => cameras.value)

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded()
})
</script>

<template>
  <BaseChart
    v-if="camerasLoaded && localCameraIds.length > 0"
    v-model:time-setup="timeSetup"
    :default-collapsed="props.defaultCollapsed"
    :height="height"
    :data-loader="localDataLoader"
    :chart-type="chartType"
    :options="options"
    :disable-control-row="props.disableControlRow"
    :hide-time-resolution="props.hideTimeResolution"
    :chain-time-setup="chainTimeSetup"
    :title="title"
    :subtitle="subtitle"
  />
</template>

<style scoped>

</style>
