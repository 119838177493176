
function useDownloadFile(blobOrURL, name) {
  let url
  if(typeof blobOrURL === 'string') { url = blobOrURL }
  if(blobOrURL instanceof Blob) { url = window.URL.createObjectURL(blobOrURL) }
  const link = document.createElement('a');
  link.href = url;
  link.download = name;
  link.click();
  link.remove();
}

export { useDownloadFile }
