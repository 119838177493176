function getAvailableProperties(properties) {
  return Object.entries(properties).map(([id, property]) => ({ id: id, title: property.title }))
}
function getOperators(properties, propertyId) {
  return properties[propertyId].operators
}

function getDataType(properties, propertyId) {
  if(!properties[propertyId]) return 'generic'
  return properties[propertyId].dataType
}
function getOptions(properties, propertyId) {
  return properties[propertyId].options.filter(option => option.enabled())
}

function getOptionTitle(properties, propertyId, optionId) {
  if(!properties[propertyId]) return optionId
  const option = properties[propertyId].options.find(option=> option.id === optionId)
  if(!option) return optionId
  return properties[propertyId].options.find(option=> option.id === optionId).title
}

function getPropertyTitle(properties, propertyId) {
  if(!properties[propertyId]) return propertyId
  return properties[propertyId].title
}

function getSortableProperties(sortableProperties, properties) {
  return sortableProperties.map(sortableProperty => {
    if(typeof sortableProperty === 'string') {
      if(Object.keys(properties).includes(sortableProperty)) {
        return { id: sortableProperty, title: properties[sortableProperty].title }
      } else {
        return  { id: sortableProperty, title: sortableProperty }
      }
    }
    return sortableProperty
  },
  )}

export { getAvailableProperties, getOperators, getDataType, getOptions, getPropertyTitle, getOptionTitle, getSortableProperties }
