import artifact from './artifact'
import incident from './incident'
import recordings from './recordings'
import dataCollectorRecord from './dataCollectorRecord'
import dataCollectorAnnotation from './dataCollectorAnnotation.js'
const filters = { artifact, incident, recordings, dataCollectorRecord, dataCollectorAnnotation }

function useFilter(filterType) {
  return filters[filterType]
}

export { useFilter }
