<script setup>

import { onMounted, ref } from 'vue';
import { loadLabelCountsMeasurement } from '@/composables/charts';
import { useMeasurementStore } from '@/store/measurements';
import CameraBaseChart from '@/components/charts/CameraBaseChart.vue';

const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregateCameras: { type: Boolean, default: false },
    aggregateLabels: { type: Boolean, default: false },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    chainTimeSetup: { type: Boolean, default: false },
    defaultCollapsed: { type: Boolean, default: false },
  },
)

const timeSetup = defineModel('timeSetup', { required: true, type: Object })
const options = ref({})


let labels = []
const readyToRender = ref(false)
async function dataLoader(cameraIds, timeSetup) {
  const data = await loadLabelCountsMeasurement({
    cameraIds: cameraIds,
    timeResolution : timeSetup.timeResolution,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    aggregateCameras: props.aggregateCameras,
    aggregateLabels: props.aggregateLabels,
    labels: labels,
    chartType: 'line',
    chainTimeSetup: { type: Boolean, default: false },
  })
  return data
}


onMounted(async () => {
  labels = await useMeasurementStore().loadUsedLabels();
  readyToRender.value = true
})


</script>

<template>
  <CameraBaseChart
    v-if="readyToRender"
    v-model:time-setup="timeSetup"
    :default-collapsed="defaultCollapsed"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :options="options"
    :title="title"
    :subtitle="subtitle"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
