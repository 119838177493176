<script setup>
import { humanReadableTimestamp } from '@/composables/datetime';
import { computed, onMounted, ref, watch } from 'vue';
import { useIncidentStore } from '@/store/incident';
import { useInstanceStore } from '@/store/backendInstances';
import WYSIWYG from '@/components/WYSIWYG.vue';
const incidentStore = useIncidentStore()
const instanceStore = useInstanceStore()
const props = defineProps({ comment: { type: Object, default: null } })
const emit = defineEmits(['triggerReload'])

const reactionPickerMenu = ref(false)
const localLoading = ref(false)
const editedText = ref('')
const editDialog = ref(false)
const hasReactions = computed(() => {
  return Object.keys(props.comment.reactions).length > 0
})

const parsedReactions = computed(() => {
  if(!hasReactions.value) return []
  const reactions = Object.keys(props.comment.reactions).map(unicodeString => {
    const count = props.comment.reactions[unicodeString].length
    const withoutPrefix = unicodeString.replaceAll('U+','')
    const codePoints = withoutPrefix.split('-')
    const integers = codePoints.map(codePoint => parseInt(codePoint, 16))
    const emoji = String.fromCodePoint(...integers)
    const accountId = instanceStore.getCurrentAccountId
    const ownReaction = props.comment.reactions[unicodeString].find(entity => entity.entityId === accountId)
    const hasOwnReaction = ownReaction !== undefined
    const displayNames = props.comment.reactions[unicodeString].map(entity => entity.displayName)
    return {
      unicodeString: unicodeString,
      emoji: emoji,
      count: count,
      hasOwnReaction: hasOwnReaction,
      displayNames: displayNames.join(', '),
    }
  })

  return reactions.sort((a,b) => (a.count > b.count) ? 1 : ((b.count > a.count) ? -1 : 0))})

const canManageComment = computed(() => {
  if(instanceStore.hasPermission('incidentComments.manage.advanced')) return true;
  const accountId = instanceStore.getCurrentAccountId

  if(!props.comment || !props.comment.author || !props.comment.author.entityId) return false
  if(instanceStore.hasPermission('incidentComments.manage') && props.comment.author.entityId === accountId) return true;
  return false
})

const showEditButton = computed(() => {
  if(!canManageComment.value) return false
  if(!props.comment.visible) return false;
  return true;
})

const showDeleteButton = computed(() => {
  if(!canManageComment.value) return false
  if(!props.comment.visible) return false;
  return true;
})

const showRestoreButton = computed(() => {
  if(!instanceStore.hasPermission('incidentComments.manage.advanced')) return false;
  if(props.comment.visible) return false;
  return true;
})

const sendButtonDisabled = computed(() => {
  if(editedText.value === null) return true
  if(editedText.value.length === 0) return true
  if(editedText.value === props.comment.text) return true
  if(editedText.value.replaceAll(' ','') === '<p></p>') return true

  return false
})

async function addReaction(emoji) {
  localLoading.value = true;
  reactionPickerMenu.value = false
  const codePoints = emoji.r.split('-')
  const unicodeString = `U+${codePoints.join('-U+')}`.toUpperCase()
  await incidentStore.addOrRemoveReactionForComment(props.comment.incidentCommentId, unicodeString)
  emit('triggerReload')
  localLoading.value = false;
}

async function updateCommentText() {
  editDialog.value = false;
  localLoading.value = true;
  await incidentStore.updateCommentText(props.comment.incidentCommentId, editedText.value)
  emit('triggerReload')
  localLoading.value = false;
}

async function addOrRemoveReaction(reaction) {
  localLoading.value = true;
  await incidentStore.addOrRemoveReactionForComment(props.comment.incidentCommentId, reaction.unicodeString)
  emit('triggerReload')
  localLoading.value = false;
}

async function deleteComment() {
  localLoading.value = true;
  await incidentStore.deleteComment(props.comment.incidentCommentId)
  emit('triggerReload')
  localLoading.value = false;
}

async function restoreComment() {
  localLoading.value = true;
  await incidentStore.restoreComment(props.comment.incidentCommentId)
  emit('triggerReload')
  localLoading.value = false;
}

watch(() => props.comment.text, () => {
  editedText.value = props.comment.text

})

onMounted(() => {
  editedText.value = props.comment.text
})

</script>

<template>
  <v-card
    variant="outlined"
    class="my-2"
    :loading="localLoading"
    density="compact"
  >
    <v-row class="ma-0 pa-0 align-center">
      <v-card-title>{{ comment.author.displayName }}</v-card-title>
      <v-card-subtitle>{{ humanReadableTimestamp(comment.creationTimestamp) }}</v-card-subtitle>
    </v-row>
    <v-card-text>
      <WYSIWYG
        v-if="comment.visible"
        :model-value="comment.text"
        :read-only="true"
      />
      <v-alert
        v-if="!comment.visible"
        type="info"
      >
        {{ $t("incidentComments.deleted_text") }}
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-row class="ma-0 pa-0 align-center">
        <v-row class="ma-0 pa-0 justify-start align-center">
          <v-btn
            variant="outlined"
            class="rounded-pill mx-1"
            size="x-small"
          >
            <v-icon icon="mdi-plus" />
            <v-menu
              v-model="reactionPickerMenu"
              activator="parent"
              :close-on-content-click="false"
            >
              <EmojiPicker
                :native="true"
                @select="addReaction"
              />
            </v-menu>
          </v-btn>
          <v-row
            v-if="hasReactions"
            class="ma-0 pa-0"
          >
            <v-tooltip
              v-for="reaction in parsedReactions"
              :key="reaction.unicodeString"
              :text="reaction.displayNames"
            >
              <template #activator="slot">
                <v-btn
                  v-bind="slot.props"
                  :color="reaction.hasOwnReaction ? 'primary' : undefined"
                  variant="outlined"
                  class="rounded-pill"
                  size="x-small"
                  @click="addOrRemoveReaction(reaction)"
                >
                  {{ reaction.emoji }} {{ reaction.count }}
                </v-btn>
              </template>
            </v-tooltip>
          </v-row>
        </v-row>
        <v-row class="ma-0 pa-0 justify-end align-center">
          <v-btn
            v-if="showRestoreButton"
            prepend-icon="mdi-restore"
            variant="outlined"
            size="small"
            class="rounded-pill"
            @click="restoreComment"
          >
            {{ $t("general_interface.buttons.restore") }}
          </v-btn>


          <v-btn
            v-if="showEditButton"
            prepend-icon="mdi-pencil"
            variant="outlined"
            size="small"
          >
            {{ $t("general_interface.buttons.edit") }}
            <v-dialog
              v-model="editDialog"
              activator="parent"
              max-width="1000px"
            >
              <v-card>
                <v-card-title>
                  {{ $t("incidentComments.edit.title") }}
                </v-card-title>
                <v-card-text>
                  <WYSIWYG v-model="editedText" />
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    color="primary"
                    variant="outlined"
                    :disabled="sendButtonDisabled"
                    @click="updateCommentText"
                  >
                    {{ $t("general_interface.buttons.save") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-btn>

          <v-btn
            v-if="showDeleteButton"
            prepend-icon="mdi-delete"
            variant="outlined"
            size="small"
            @click="deleteComment"
          >
            {{ $t("general_interface.buttons.delete") }}
          </v-btn>
        </v-row>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<style scoped>

</style>
