import { useMeasurementStore } from '@/store/measurements';
import { useCamerasStore } from '@/store/cameras';
import i18n from '../plugins/i18n'
import { useIncidentStore } from '@/store/incident';
import _ from 'lodash';
import { useVMSInstanceStore } from '@/store/vmsInstanceStore';
import { DateTime } from 'luxon';
import { useRegionStore } from '@/store/regions.js'

const { t } = i18n.global

async function getCameraDimension(cameraIds) {
  const camerasStore = useCamerasStore()
  const nonDecommissionedCameras = camerasStore.getNonDecommissionedCameras()
  let needsToReload = false
  for (const cameraId of cameraIds) {
    if (!nonDecommissionedCameras.find(camera => camera.cameraId === cameraId)) {
      needsToReload = true
      break
    }
  }
  if (needsToReload) await camerasStore.keepCamerasLoaded()
  const cameras = camerasStore.getNonDecommissionedCameras()
  return cameras.filter(({ cameraId }) => cameraIds.includes(cameraId)).map(({ cameraId, name }) => ({
    value: cameraId,
    text: name,
  }))
}

async function getVMSInstanceDimension(vmsInstanceIds) {
  const vmsInstanceStore = useVMSInstanceStore()
  if (!vmsInstanceStore.getAllVMSInstances) await vmsInstanceStore.queryVMSInstances({})
  if (!vmsInstanceIds || vmsInstanceIds.length === 0) return vmsInstanceStore.getAllVMSInstances.map(({
    name,
    vmsInstanceId,
  }) => ({
    value: vmsInstanceId,
    text: name,
  }))
  return vmsInstanceStore.getAllVMSInstances
    .filter(({ vmsInstanceId }) => vmsInstanceIds.includes(vmsInstanceId)).map(({
      name, vmsInstanceId,
    }) => ({
      value: vmsInstanceId, text: name,
    }))
}

function getLabelDimension(labels) {
  return labels.map(label => ({ value: label, text: t('measurements.labelCount.labels.' + label) }))
}

function getRegionDimension(regionType, regionSpecifications) {
  return regionSpecifications.map(specification => ({ value: specification, text: `${specification} (${regionType})` }))
}

function buildIncidentDatasets(rawData, dimensionKeys = [], dimensions = {}, dimensionNamingDictionary = {}) {
  const data = { series: [] }

  if (dimensionKeys.length >= 1) {
    const sortedDimensions = []
    dimensionKeys.forEach(dimensionKey => {
      sortedDimensions.push([...dimensions[dimensionKey]].sort((a, b) => a.text.localeCompare(b.text)).map(({ value }) => value))
    })

    let datasetSelectors = sortedDimensions.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))
    if (dimensionKeys.length === 1) {
      datasetSelectors = datasetSelectors.map(datasetSelector => [datasetSelector]);
    }

    let i = 0
    for (const datasetSelector of datasetSelectors) {
      const series = {}
      const labelList = []
      let filteredData = [...rawData]
      let dimensionKeyIndex = 0
      for (const aggregateDimensionKey of dimensionKeys) {
        let labelEntry = ''
        if (dimensionKeys.length > 1) {
          labelEntry = labelEntry + dimensionNamingDictionary[aggregateDimensionKey] + ': '
        }
        labelEntry = labelEntry + dimensions[aggregateDimensionKey].find(({ value }) => value === datasetSelector[dimensionKeyIndex]).text

        labelList.push(labelEntry)
        filteredData = filteredData.filter(dataPoint => _.get(dataPoint, aggregateDimensionKey) === datasetSelector[dimensionKeyIndex])
        dimensionKeyIndex = dimensionKeyIndex + 1
      }
      series.data = filteredData
      series.name = labelList.join('; ')

      i = i + 1

      data.series.push(series)
    }


  } else {
    data.series.push({
      data: rawData,
      name: 'Total',
    })
  }
  data.seriesNames = data.series.map(series => series.name)

  return data

}

function buildTimeSeriesDatasets(rawData, dimensionKeys = [], dimensions = {}, dimensionNamingDictionary = {}, chartType = 'line') {
  const data = { series: [] }
  if (dimensionKeys.length >= 1) {
    const sortedDimensions = []
    dimensionKeys.forEach(dimensionKey => {
      sortedDimensions.push([...dimensions[dimensionKey]].sort((a, b) => a.text.localeCompare(b.text)).map(({ value }) => value))
    })
    //cartesian Product of all
    let datasetSelectors = sortedDimensions.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())))
    if (dimensionKeys.length === 1) {
      datasetSelectors = datasetSelectors.map(datasetSelector => [datasetSelector]);
    }

    let i = 0
    for (const datasetSelector of datasetSelectors) {
      const series = {
      }
      const labelList = []
      let filteredData = [...rawData]
      let dimensionKeyIndex = 0
      for (const aggregateDimensionKey of dimensionKeys) {
        let labelEntry = ''
        if (dimensionKeys.length > 1) {
          labelEntry = labelEntry + dimensionNamingDictionary[aggregateDimensionKey] + ': '
        }
        labelEntry = labelEntry + dimensions[aggregateDimensionKey].find(({ value }) => value === datasetSelector[dimensionKeyIndex]).text
        labelList.push(labelEntry)
        filteredData = filteredData.filter(dataPoint => dataPoint[aggregateDimensionKey] === datasetSelector[dimensionKeyIndex])
        dimensionKeyIndex = dimensionKeyIndex + 1
      }
      series.data = filteredData
      series.name = labelList.join('; ')
      series.datasetSelector = [...datasetSelector]
      series.dimensionKeys = dimensionKeys
      i = i + 1

      data.series.push(series)
    }
  } else {
    data.series.push({
      data: rawData,
      name: 'Total',
    })
  }
  data.seriesNames = data.series.map(series => series.name)

  //for a polarArea setup the data to only reflect numbers of occurence
  if (chartType === 'polarArea') {
    data.series = data.series.map(series => {
      const totalCount = series.data.length
      return series.data.filter(dataPoint => dataPoint.y === 0).length / totalCount * 100
    })
  }

  if (chartType === 'percentageBar') {
    data.series = data.series.map(series => {
      const totalCount = series.data.length
      const dayPointCount = series.data.filter(dataPoint => dataPoint.y === 0).length
      return Object.assign(series, {
        x: series.name,
        y: Math.round(dayPointCount ? dayPointCount / totalCount * 100 : 0),
        fillColor: series.color,
        strokeColor: series.color,
      })
    })
    data.series = [{ data: data.series, name: 'Day-Vision' }]
  }
  return data
}

async function loadGenericOpsMeasurement({
  vmsInstanceNames,
  timeResolution,
  measurement,
  field,
  timeQuery,
  aggregate,
  where = {},
}) {
  const measurementStore = useMeasurementStore()
  const rawData = await measurementStore.loadSimpleMeasurementForMultipleBarns(measurement, field, vmsInstanceNames, timeQuery, timeResolution, aggregate, 'mean', where)
  const rawInstances = [...new Set(rawData.map(dataPoint => dataPoint.vmsInstanceName))]
  if (!aggregate) return buildTimeSeriesDatasets(rawData, ['vmsInstanceName'], { vmsInstanceName: getVMSInstanceDimension(rawInstances) }, { vmsInstanceName: 'VMS Instance' }, 'line')
  return buildTimeSeriesDatasets(rawData, [], {}, {}, 'line')

}

function transformIsoToUnixTimestamps(data) {
  return data.map(dataPoint => Object.assign(dataPoint, { x: DateTime.fromISO(dataPoint.x).toMillis() }))
}

async function loadGenericMeasurement({
  cameraIds, timeResolution, measurement, field, timeQuery, aggregate, xAggregation='mean', skipTimeResolution = false,
}) {
  const measurementStore = useMeasurementStore()
  const rawData = await measurementStore.loadSimpleMeasurementForMultipleCameras(measurement, field, cameraIds, timeQuery, timeResolution, aggregate, xAggregation, skipTimeResolution)

  const rawDataWithUnixTimestamps = transformIsoToUnixTimestamps(rawData)

  if (!aggregate) return buildTimeSeriesDatasets(rawDataWithUnixTimestamps, ['camera'], { camera: await getCameraDimension(cameraIds) }, { camera: 'Camera' }, 'line')
  return buildTimeSeriesDatasets(rawDataWithUnixTimestamps, [], {}, {}, 'line')
}

async function loadLabelCountsMeasurement({
  cameraIds,
  timeResolution,
  timeQuery,
  aggregateCameras = false,
  aggregateLabels = false,
  labels = undefined,
}) {
  const measurementStore = useMeasurementStore()
  const rawData = await measurementStore.loadLabelCountsForMultipleCameras(cameraIds, timeQuery, timeResolution, labels, aggregateCameras, aggregateLabels)
  const rawDataWithUnixTimestamps = transformIsoToUnixTimestamps(rawData)
  const dimensionKeys = []
  const dimensions = {}
  const dimensionNamingDictionary = {}
  if (!aggregateCameras) {
    dimensionKeys.push('camera')
    dimensions.camera = await getCameraDimension(cameraIds)
    dimensionNamingDictionary.camera = 'Camera'
  }
  if (!aggregateLabels) {
    dimensionKeys.push('label')
    dimensions.label = getLabelDimension(labels)
    dimensionNamingDictionary.label = 'Label'
  }
  return buildTimeSeriesDatasets(rawDataWithUnixTimestamps, dimensionKeys, dimensions, dimensionNamingDictionary)
}

async function loadGridScoreDerivationMeasurement({
  timeResolution,
  aggregateCameras=false,
  timeQuery,
  cameraIds,
}) {
  const measurementStore = useMeasurementStore()
  const rawData = await measurementStore.loadGridScoreDerivationMeasurement(timeQuery, timeResolution, cameraIds, aggregateCameras)
  const rawDataWithUnixTimestamps = transformIsoToUnixTimestamps(rawData)
  const dimensionKeys = []
  const dimensions = {}
  const dimensionNamingDictionary = {}

  if (!aggregateCameras) {
    dimensionKeys.push('camera')
    dimensions.camera = await getCameraDimension(cameraIds)
    dimensionNamingDictionary.camera = 'Camera'
  }
  const data = buildTimeSeriesDatasets(rawDataWithUnixTimestamps, dimensionKeys, dimensions, dimensionNamingDictionary)
  return data
}

async function loadRegionMeasurement({
  timeResolution,
  timeQuery,
  aggregateLabels = false,
  labels = undefined,
  regionType,
  regionSpecifications,
  groupByPen = false,
}) {
  const measurementStore = useMeasurementStore()
  const rawData = await measurementStore.loadRegionCounts(timeQuery, timeResolution, labels, aggregateLabels, regionType, regionSpecifications, groupByPen)
  const rawDataWithUnixTimestamps = transformIsoToUnixTimestamps(rawData)
  const dimensionKeys = []
  const dimensions = {}
  const dimensionNamingDictionary = {}

  dimensionKeys.push(regionType)
  dimensions[regionType] = getRegionDimension(regionType, regionSpecifications)
  dimensionNamingDictionary[regionType] = 'Region'

  if (!aggregateLabels) {
    dimensionKeys.push('label')
    dimensions.label = getLabelDimension(labels)
    dimensionNamingDictionary.label = 'Label'
  }
  if(groupByPen) {
    const pens = useRegionStore().getPenRegionSpecifications().map(({ regionSpecificationId }) => regionSpecificationId)
    dimensionKeys.push('pen')
    dimensions.pen = getRegionDimension('pen', pens)
    dimensionNamingDictionary.pen = 'Pen'
  }
  const data = buildTimeSeriesDatasets(rawDataWithUnixTimestamps, dimensionKeys, dimensions, dimensionNamingDictionary)
  return data
}

async function loadHealthChecks({
  componentType,
  componentIds,
  timeQuery,
  aggregate = false,
  chartType = 'rangeBar',
  requiredVMSInstanceTags = [],
  disallowedVMSInstanceTags = [],
}) {
  const incidentStore = useIncidentStore()

  const inTimeFrameQuery = [
    { key: 'identifier.componentType', operator: '==', value: componentType },
  ]

  const currentlyOpenQuery = [
    { key: 'identifier.componentType', operator: '==', value: componentType },
    { key: 'state', operator: '==', value: 'isBelowThreshold' },
  ]
  const order = [{ 'firstEventTimestamp': 'asc' }]
  if (timeQuery.start) {
    inTimeFrameQuery.push({ key: 'firstEventTimestamp', operator: '>=', value: timeQuery.start })
  }
  if (componentIds && componentIds.length > 0) {
    inTimeFrameQuery.push({ key: 'identifier.componentId', operator: 'in', value: componentIds })
    currentlyOpenQuery.push({ key: 'identifier.componentId', operator: 'in', value: componentIds })
  }


  const inTimeFrameOpenedIncidents = await incidentStore.queryIncidents({ query: inTimeFrameQuery, order })

  const currentlyOpenIncidents = await incidentStore.queryIncidents({ query: currentlyOpenQuery })
  let rawData = []
  if (inTimeFrameOpenedIncidents) rawData.push(...inTimeFrameOpenedIncidents)

  if (currentlyOpenIncidents)
    for (const incident of currentlyOpenIncidents) {
      if (!rawData.find(({ incidentId }) => incidentId === incident.incidentId)) {
        rawData.push(incident)
      }
    }
  if (componentType === 'vms') {
    if (disallowedVMSInstanceTags.length > 0) {
      const validInstances = await useVMSInstanceStore().getAllVMSInstances.filter(({ tags }) => {
        for (const disallowedTag of disallowedVMSInstanceTags) {
          if (tags.includes(disallowedTag)) return false
        }
        return true
      }).map(({ vmsInstanceId }) => vmsInstanceId)
      rawData = rawData.filter(({ identifier }) => validInstances.includes(identifier.componentId))
    }
    if (requiredVMSInstanceTags.length > 0) {
      const validInstances = await useVMSInstanceStore().getAllVMSInstances.filter(({ tags }) => {
        for (const requiredTag of requiredVMSInstanceTags) {
          if (!tags.includes(requiredTag)) return false
        }
        return true
      }).map(({ vmsInstanceId }) => vmsInstanceId)
      rawData = rawData.filter(({ identifier }) => validInstances.includes(identifier.componentId))
    }

  }
  const dimensionKeys = []
  const dimensions = {}
  const dimensionNamingDictionary = {}
  if (!aggregate) {
    dimensionKeys.push('identifier.componentId')
    if (componentType === 'vms') {
      dimensions['identifier.componentId'] = await getVMSInstanceDimension(componentIds)
      dimensionNamingDictionary['identifier.componentId'] = 'VMS Instance'
    }
    if (componentType === 'camera') {
      dimensions['identifier.componentId'] = await getCameraDimension(componentIds)
      dimensionNamingDictionary['identifier.componentId'] = 'Camera'
    }
  }
  const rawDataWithUnixTimestamps = transformIsoToUnixTimestamps(rawData)
  const data = buildIncidentDatasets(rawDataWithUnixTimestamps, dimensionKeys, dimensions, dimensionNamingDictionary, chartType)


  //if a rangeBar chart is requested, set up the data for a timeline-gantt chart
  if (chartType === 'rangeBar') {
    for (const series of data.series) {
      const dataList = [...series.data]
      series.data = dataList.map(dataPoint => ({
        x: series.name,
        y: [dataPoint.firstEventTimestamp, dataPoint.state === 'isBelowThreshold' ? Date.now() : dataPoint.lastEventTimestamp],
      }))
    }
  }
  return data
}

async function loadNightVisionMeasurement({
  cameraIds,
  timeResolution,
  timeQuery,
  aggregateCameras = false,
  chartType = 'rangeBar',
}) {
  const measurementStore = useMeasurementStore()
  const rawData = await measurementStore.loadSimpleMeasurementForMultipleCameras('imageQuality', 'nightvision', cameraIds, timeQuery, timeResolution, aggregateCameras, 'first')
  const dimensionKeys = []
  const dimensions = {}
  const dimensionNamingDictionary = {}
  if (!aggregateCameras) {
    dimensionKeys.push('camera')
    dimensions.camera = await getCameraDimension(cameraIds)
    dimensionNamingDictionary.camera = 'Camera'
  }
  const data = buildTimeSeriesDatasets(rawData, dimensionKeys, dimensions, dimensionNamingDictionary, chartType)

  for (let i = 0; i < data.series.length; i++) {
    const segments = []
    const dataList = [...data.series[i].data]
    let currentSegment = []
    let currentValue = null
    while (dataList.length > 0) {
      const currentDataPoint = dataList.shift()
      currentSegment.push(currentDataPoint.x)
      if (currentDataPoint.y !== currentValue) {
        if (currentValue !== null) {
          segments.push({
            x: DateTime.fromISO(currentSegment[0]).toMillis(),
            x2: DateTime.fromISO(currentSegment[currentSegment.length - 1]).toMillis(),
            color: currentValue ? 'blue' : 'yellow',
            y: i,
          })
          currentSegment = []
        }
        currentValue = currentDataPoint.y
      }
    }
    if (currentSegment.length > 0) {
      segments.push({
        x: DateTime.fromISO(currentSegment[0]).toMillis(),
        x2: DateTime.fromISO(currentSegment[currentSegment.length - 1]).toMillis(),
        color: currentValue ? 'blue' : 'yellow',
        y: i,
      })
    }
    delete data.series[i].color
    data.series[i].pointPadding = 0
    data.series[i].pointWidth = 10
    data.series[i].data = segments
  }
  return data
}

export {
  loadGenericOpsMeasurement,
  loadGenericMeasurement,
  loadLabelCountsMeasurement,
  loadNightVisionMeasurement,
  loadRegionMeasurement,
  loadGridScoreDerivationMeasurement,
  loadHealthChecks,
}
