import ImageSourceProvider from '@/components/canvas/CanvasElementSourceProviders/ImageSourceProvider';
import { useArtifactStore } from '@/store/artifacts';
import i18n from '@/plugins/i18n';
import { useCameraName } from '@/composables/cameraHelpers';
import { humanReadableTimestamp } from '@/composables/datetime';

const { t } = i18n.global

class ArtifactProvider extends ImageSourceProvider {
  constructor(settings, providerType) {
    super(settings, providerType);
    this.artifactId = null;
  }

  setTimestamp(timestamp) {
    super.setTimestamp(timestamp);
    this.artifactId = null;
  }

  async loadSourceFromBackend(height, controller, isInView) {
    if (!this.artifactId) {
      try {
        this.artifactId = await useArtifactStore().getLatestArtifactForCameraAndArtifactTypeWithTimestamp(this.cameraId, this.providerType, this.timestamp.toMillis(), controller.signal, isInView)
      } catch(e){
        return Promise.reject(e)
      }
    }
    if (!this.artifactId) return Promise.resolve(null)
    if(!useArtifactStore().artifacts[this.artifactId]) {
      return Promise.resolve(null)
    }
    const artifact = useArtifactStore().artifacts[this.artifactId].data
    this.tooltipText = () => t('canvas.artifact_provider_tooltip', {
      artifactType: t('canvas.element_providers.' + this.providerType),
      cameraName: useCameraName(this.cameraId),
      timestamp: humanReadableTimestamp(artifact.timestamp),
    })
    return new Promise((resolve, reject) => {
      useArtifactStore().loadArtifact(this.artifactId, height, controller.signal, isInView).then(() => {
        if (!useArtifactStore().artifacts[this.artifactId].payload || !useArtifactStore().artifacts[this.artifactId].payload[height]) reject(new Error('No payload for height'))
        resolve(useArtifactStore().artifacts[this.artifactId].payload[height])
      }).catch(error => {
        if(error.message === 'Aborted')
          return resolve(null)
        resolve(error)
      })
    })
  }

  handleDoubleClick(ev) {
    super.handleDoubleClick(ev);
    if (this.artifactId === null) return;
    this.router.push({ name: 'Artifact', params: { artifactId: this.artifactId } })
  }
}

export default ArtifactProvider;
