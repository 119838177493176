<script setup>
import Artifact from '@/components/artifacts/ArtifactDisplay.vue';
import { computed, ref, watch } from 'vue';
import { useArtifactStore } from '@/store/artifacts';
import { useDisplay } from 'vuetify';
import { useMiscellaneousStore } from '@/store/miscellaneous';
const miscellaneousStore = useMiscellaneousStore()
const artifactStore = useArtifactStore()
const props = defineProps({
  artifacts: { type: Array, default: () => [] },
})
const { mobile } = useDisplay()

const viewMode = ref(miscellaneousStore.artifactListDisplayMode)
const downloadFinished = ref(0)
const downloadFailed = ref(0)
const downloadTotal = ref(0)
const currentlyDownloading = ref(false)
const downloadProgress = computed(() => downloadFinished.value + downloadFailed.value)
async function downloadAll() {
  const promises = []
  downloadFinished.value = 0
  downloadFailed.value = 0
  currentlyDownloading.value = true
  downloadTotal.value = props.artifacts.length
  for (let artifact of props.artifacts) {
    promises.push(artifactStore.downloadArtifactPayload(artifact.artifactId)
      .then(() => downloadFinished.value++)
      .catch(() => downloadFailed.value++))
  }
  await Promise.all(promises)
  currentlyDownloading.value = false
}

async function downloadAndSaveAll() {
  const promises = []
  downloadFinished.value = 0
  downloadFailed.value = 0
  currentlyDownloading.value = true
  downloadTotal.value = props.artifacts.length
  for(let artifact of props.artifacts) {
    promises.push(
      artifactStore.downloadAndSaveArtifactPayload(artifact.artifactId)
        .then(successful => {if (successful) downloadFinished.value++; else downloadFailed.value++})
        .catch(),
    )
  }
  await Promise.all(promises)
  currentlyDownloading.value = false
}

watch(viewMode, () => {
  miscellaneousStore.artifactListDisplayMode = viewMode.value
})

</script>

<template>
  <v-container
    :fluid="true"
    class="mx-0 my-4 pa-1"
  >
    <v-layout-card>
      <v-row
        justify="end"
        :dense="true"
      >
        <v-col>
          <v-row class="justify-center my-2">
            <v-btn
              class="ma-2 rounded-pill"
              variant="outlined"
              @click="downloadAll"
            >
              {{ $t("general_interface.buttons.download_all") }}
            </v-btn>
            <v-btn
              class="ma-2 rounded-pill"
              variant="outlined"
              @click="downloadAndSaveAll"
            >
              {{ $t("general_interface.buttons.download_and_save_all") }}
            </v-btn>
          </v-row>
        </v-col>
        <v-col
          v-if="!mobile"
          cols="auto"
        >
          <v-btn-toggle
            v-model="viewMode"
            :mandatory="true"
          >
            <v-btn
              value="table"
              icon="mdi-table"
              color="primary"
              variant="outlined"
            />
            <v-btn
              value="list"
              icon="mdi-list-box"
              color="primary"
              variant="outlined"
            />
          </v-btn-toggle>
        </v-col>
        <v-col
          v-if="currentlyDownloading"
          :cols="12"
          class="px-4"
        >
          <v-progress-linear
            height="20"
            rounded
            :max="downloadTotal"
            :model-value="downloadProgress"
            color="primary"
          >
            <template #default="{value}">
              <strong>
                {{ Math.ceil(value).toString()+"%" }}
              </strong>
            </template>
          </v-progress-linear>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          v-for="artifact of artifacts"
          :key="artifact.artifactId"
          :cols="viewMode === 'table' ? 4 && !mobile : 12"
          :min-width="100"
          :min-height="100"
        >
          <v-lazy>
            <Artifact :artifact-id="artifact.artifactId" />
          </v-lazy>
        </v-col>
      </v-row>
    </v-layout-card>
  </v-container>
</template>

<style scoped>

</style>
