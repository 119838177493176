<script setup>
import { loadNightVisionMeasurement } from '@/composables/charts';
import CameraBaseChart from '@/components/charts/CameraBaseChart.vue';
import { onMounted, ref, watch } from 'vue';
import { useTheme } from 'vuetify';
import { useCamerasStore } from '@/store/cameras.js';
const camerasStore = useCamerasStore()
const canBeRendered = ref(false)
const minHeight = ref(1500)
const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregate: { type: Boolean, default: false },
    title: { type: String, default: null },
    subtitle: { type: String, default: '' },
    chainTimeSetup: { type: Boolean, default: false },
    defaultCollapsed: { type: Boolean, default: false },
  },
)
const timeSetup = defineModel('timeSetup', { required: true, type: Object })

async function dataLoader(cameraIds, timeSetup) {
  const data = await loadNightVisionMeasurement({
    cameraIds: cameraIds,
    timeResolution: 1000 * 60,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    aggregateCameras: props.aggregate,
    chartType: 'rangeBar',
  })
  return data
}
watch(() => camerasStore.getNonDecommissionedCameras, () => {
  canBeRendered.value = false
  const cameras = camerasStore.getNonDecommissionedCameras
  minHeight.value = cameras.length * 20
  canBeRendered.value = true
}, { immediate: true, deep: true })

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded()
})

</script>

<template>
  <CameraBaseChart
    v-if="canBeRendered"
    v-model:time-setup="timeSetup"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    chart-type="xrange"
    :subtitle="subtitle"
    :default-collapsed="defaultCollapsed"
    :title="title"
    :height="Math.max(minHeight + 200, 600)"
    :options="{
      chart: {
        scrollablePlotArea: {
          minHeight: minHeight,
        },
        backgroundColor: useTheme().current.value.colors.surface,
      },
      legend:{ enabled:false },
      xAxis: {
        backgroundColor: useTheme().current.value.colors.surface,
      },
      yAxis: {
        backgroundColor: useTheme().current.value.colors.surface,
        labels: {
          style: {
            color: useTheme().current.value.colors.onSurface,
          }
        },

        title: {text: ''},
        type: 'category',
        minPadding: 0,
        reversed: true
      }
    }"
    :chain-time-setup="chainTimeSetup"
  />
</template>

<style scoped>

</style>
