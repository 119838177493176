import { defineStore } from 'pinia'
import { randomString } from '@/composables/helperFunctions.js';
import { useBranchString, useVersionString } from '@/composables/version.js';
import { useInstanceStore } from '@/store/backendInstances.js';
import { useDownloadFile } from '@/composables/downloadFile.js';
import { parse, stringify } from 'zipson'
let currentBatch = []
let interval = null
export const useLogsStore = defineStore('logs', {
  state: () => ({
    sessions: {},
    logSession: null,
  }),
  persist: {
    pick: ['sessions'],
    serializer: {
      deserialize: parse,
      serialize: stringify,
    },
  },
  getters: {
    getLogEntries: state => Object.values(state.sessions).flatMap(session => session.entries),
    getLogEntriesForCurrentSession: state => state.sessions[state.logSession].entries,
  },
  actions: {
    addRandomLogs(number) {
      const newLogs = []
      for (let i = 0; i < number; i++) {
        newLogs.push({
          message: randomString(50),
          tag: randomString(10),
          globalUniqueIdentifier: randomString(20),
          level: 'INFO',
          timestamp: Date.now(),
          session: this.logSession,
          version: useVersionString(),
          branch: useBranchString(),
        })
      }
      if (!Object.keys(this.sessions).includes(this.logSession)) {
        this.sessions[this.logSession] = {
          entries: newLogs,
          started: Date.now(),
          lastUpdated: Date.now(),
        }
      } else {
        this.sessions[this.logSession] = {
          entries: newLogs.concat(this.sessions[this.logSession].entries),
          started: this.sessions[this.logSession].started,
          lastUpdated: Date.now(),
        }
      }
      let deletedOldSession = true
      while (deletedOldSession) {
        deletedOldSession = this.clearOldLogs()
      }

    },
    addLogEntry({
      message,
      tag,
      globalUniqueIdentifier = undefined,
      level = 'INFO',
      error = undefined,
    }) {
      if (!this.logSession) this.logSession = randomString(8)
      if (globalUniqueIdentifier === undefined) globalUniqueIdentifier = useInstanceStore().selectedInstanceId

      if (level === 'ERROR' && ['dev', 'staging', 'local'].includes(useBranchString()))
        // eslint-disable-next-line no-console
        console.error(message, error)

      if (level === 'WARN' && ['dev', 'staging', 'local'].includes(useBranchString()))
        // eslint-disable-next-line no-console
        console.warn(message, error)

      const entry = {
        version: useVersionString(),
        branch: useBranchString(),
        globalUniqueIdentifier: globalUniqueIdentifier,
        session: this.logSession,
        message,
        error: error ? error.message : undefined,
        tag,
        level,
        timestamp: Date.now(),
      }

      currentBatch.push(entry)
      this.ensureInterval()

    },

    ensureInterval() {
      if (interval) return
      interval = setInterval(() => {
        if (currentBatch.length === 0) return
        const firstEntry = currentBatch[0]
        const lastEntry = currentBatch[currentBatch.length - 1]
        if (!Object.keys(this.sessions).includes(this.logSession)) {
          this.sessions[this.logSession] = {
            entries: currentBatch,
            started: firstEntry.timestamp,
            lastUpdated: lastEntry.timestamp,
          }
        } else {
          this.sessions[this.logSession] = {
            entries: this.sessions[this.logSession].entries.concat(currentBatch),
            started: this.sessions[this.logSession].started,
            lastUpdated: lastEntry.timestamp,
          }
        }
        currentBatch = []
        let deletedOldSession = true
        while (deletedOldSession) {
          deletedOldSession = this.clearOldLogs()
        }
      }, 10000)
    },
    clearOldLogs() {
      if (Object.keys(this.sessions).length <= 20) return false
      let oldestTimestamp = Date.now()
      let oldestSession
      Object.keys(this.sessions).forEach(sessionId => {
        if (this.sessions[sessionId].lastUpdated <= oldestTimestamp) {
          oldestTimestamp = this.sessions[sessionId].lastUpdated
          oldestSession = sessionId
        }
      })
      if (oldestSession) {
        delete this.sessions[oldestSession]
        return true
      }
      return false
    },
    clearAllLogs() {
      this.sessions = []
    },
    downloadLogs() {
      let text = ''
      this.getLogEntries.forEach(entry => {
        text += new Date(entry.timestamp).toISOString() + ' ' + entry.level + ' ' + entry.tag + ' ' + entry.globalUniqueIdentifier + ' ' + entry.message + '\n'
      })
      const blob = new Blob([text], { type: 'text/plan;charset=utf-8' })
      useDownloadFile(blob, new Date().toISOString() + '_logs.txt')
    },
  },
})
