<script setup>
import { writeToClipboard } from '@/composables/clipboard';
import { useI18n } from 'vue-i18n';
import { useToast } from 'vue-toastification';
const i18n = useI18n();
const { t } = i18n
const toast = useToast();

const props = defineProps({
  url: { type: String, required: true },
  text: { type: String, required: true },
  title: { type: String, required: false, default: 'PigBrother' },
})

async function share() {
  try {
    return navigator.share({
      title: props.title,
      text: props.text,
      url: props.url,
    })
  } catch {
    const copyResult = await writeToClipboard(props.url)
    if(copyResult) {
      toast.success(t('toast.copied_to_clipboard'))
    } else {
      toast.error(t('toast.copy_failed'))
    }
  }



}

</script>

<template>
  <v-btn
    prepend-icon="mdi-share-variant"
    class="rounded-pill"
    @click="share"
  >
    {{ $t("general_interface.buttons.share") }}
  </v-btn>
</template>

<style scoped>

</style>
