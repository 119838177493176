<script setup>
import { useFilter } from '@/composables/filters/filters';
import { computed, onMounted, ref, watch } from 'vue';
import { ALL, ANY, CONTAINS, IN, NIN } from '@/composables/filters/operators';
import { useCamerasStore } from '@/store/cameras';
import DateTimePicker from '@/components/DateTimePicker.vue';
import { inputRequiredRule } from '@/composables/inputRules';
const props = defineProps({ filterType: { type: String, default: '' } })
const emit = defineEmits(['cancel', 'confirm'])
import { useToast } from 'vue-toastification';
const toast = useToast()
import i18n from '../plugins/i18n'
const { t } = i18n.global
const propertyId = ref(null)
const operatorId = ref(null)
const autocompleteValue = ref(null)
const cameraInputValue  = ref(null)
const stringValue = ref(null)
const dateTimeInputValue = ref(null)
const options = ref([])
const form = ref(null)
const filter = computed(() => {
  return useFilter(props.filterType)
})

const availableProperties = computed(() => {
  return filter.value.getAvailableProperties()
})

const availableOperators = computed(() => {
  if(!propertyId.value) return []
  return filter.value.getOperators(propertyId.value)
})

const valueInputMode = computed(() => {
  if(!propertyId.value) return null
  return filter.value.getDataType(propertyId.value)
})

const filterOptions = computed(() => {
  if(valueInputMode.value !== 'autocomplete') return []
  return filter.value.getOptions(propertyId.value)
})

const arrayOperator = computed(() => {
  return [NIN, IN, CONTAINS, ALL, ANY].includes(operatorId.value)
})

const filterElement = computed(() => {

  let filterValue = null

  if(valueInputMode.value === 'String' || valueInputMode.value === 'Number') {
    filterValue = arrayOperator.value ? options.value : stringValue.value
  }
  if(valueInputMode.value === 'camera') filterValue = cameraInputValue.value
  if(valueInputMode.value === 'timestamp') filterValue = dateTimeInputValue.value ? dateTimeInputValue.value.toMillis() : null
  if(valueInputMode.value === 'autocomplete') filterValue = autocompleteValue.value

  if(!propertyId.value) return null
  return {
    key: propertyId.value,
    operator:operatorId.value,
    value: filterValue,
  }
})

const cameras = computed(() => {
  return useCamerasStore().getNonDecommissionedCameras()
})


watch(arrayOperator, (isArrayOperator, wasArrayOperator) => {
  if(isArrayOperator !== wasArrayOperator) {
    autocompleteValue.value = null
    cameraInputValue.value = null
  }
})

watch(propertyId, (currentPropertyId, previousPropertyId) => {
  if(currentPropertyId === previousPropertyId) return;
  if(!availableOperators.value.includes(operatorId))
    if(availableOperators.value.length) {
      operatorId.value = availableOperators.value[0]
    } else operatorId.value = null
})

watch(valueInputMode, (currentValueInputMode, previousValueInputMode) => {
  if(currentValueInputMode === previousValueInputMode) return;
  cameraInputValue.value = null
  dateTimeInputValue.value = null
  stringValue.value = null
})

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded()
})

const addStringValue = function() {
  if(!arrayOperator.value) return
  if(!stringValue.value) return
  options.value.push(stringValue.value)
  stringValue.value = null
}
const removeOption = function(index) {
  options.value.splice(index, 1)
}

const cancel = function() {
  emit('cancel')
}

const confirm = function() {
  form.value.validate()
  if(!form.value.isValid) {
    toast.error(t('toast.fix_errors_above'))
    return;
  }
  emit('confirm', filterElement.value)
}

</script>

<template>
  <v-layout-card>
    <v-card-title>
      {{ $t("general_interface.filter.add_filter") }}
    </v-card-title>
    <v-card-text>
      <v-form ref="form">
        <v-select
          v-model="propertyId"
          :label="$t('general_interface.filter.new.property')"
          :items="availableProperties"
          item-value="id"
          item-title="title"
          variant="outlined"
          :rules="[inputRequiredRule]"
        />

        <v-select
          v-model="operatorId"
          :auto-select-first="true"
          :disabled="availableOperators.length === 0"
          :label="$t('general_interface.filter.new.operator')"
          :items="availableOperators"
          variant="outlined"
          :rules="[inputRequiredRule]"
        />

        <v-autocomplete
          v-if="valueInputMode === 'autocomplete'"
          v-model="autocompleteValue"
          :disabled="!operatorId"
          :items="filterOptions"
          item-value="id"
          item-title="title"
          :multiple="arrayOperator"
          :chips="arrayOperator"
          :closable-chips="arrayOperator"
          :clearable="arrayOperator"
          variant="outlined"
          :rules="[inputRequiredRule]"
        />

        <v-text-field
          v-if="valueInputMode === 'String' || valueInputMode === 'Number'"
          v-model="stringValue"
          :disabled="!operatorId"
          :type="valueInputMode === 'String' ? 'text' : 'number'"
          :append-icon="arrayOperator ? 'mdi-plus' : undefined"
          :on-click:append="addStringValue"
          :rules="[inputRequiredRule]"
          variant="outlined"
          @keydown.enter="addStringValue"
        />
        <v-autocomplete
          v-if="valueInputMode === 'camera'"
          v-model="cameraInputValue"
          :disabled="!operatorId"
          :items="cameras"
          item-value="cameraId"
          item-title="name"
          :multiple="arrayOperator"
          :chips="arrayOperator"
          :closable-chips="arrayOperator"
          :clearable="arrayOperator"
          variant="outlined"
          :rules="[inputRequiredRule]"
        />

        <DateTimePicker
          v-if="valueInputMode=== 'timestamp'"
          v-model="dateTimeInputValue"
          :disabled="!operatorId"
        />
        <v-container
          v-if="arrayOperator && ['String', 'Number'].includes(valueInputMode)"
          class="ma-0 pa-0"
        >
          <v-chip
            v-for="(option, index) in options"
            :key="index"
            close-icon="mdi-close"
            :closable="true"
            class="ma-1"
            @close="removeOption(index)"
          >
            {{ option }}
          </v-chip>
        </v-container>
      </v-form>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="error"
        variant="text"
        class="rounded-pill"
        @click="cancel"
      >
        {{ $t("general_interface.buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        variant="flat"
        @click="confirm"
      >
        {{ $t("general_interface.buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
