import { useInstanceStore } from '@/store/backendInstances';
import backendHandler from '@/BackendAPI';


const activeGUID = globalUniqueIdentifier => {
  if(globalUniqueIdentifier) return globalUniqueIdentifier
  return useInstanceStore().selectedInstanceId
}

const isAuthenticated = (globalUniqueIdentifier= undefined) => {
  return backendHandler.getOrCreateBackendAPIInstance(activeGUID(globalUniqueIdentifier)).accessibleState.loggedIn
}

const canViewCameras = (globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  return useInstanceStore().hasPermission('cameras.view', guid)
}

const canViewAllArtifacts = (globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  return useInstanceStore().hasPermission('artifact.view', guid)
}

const canViewArtifactType = (artifactType, globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  if(canViewAllArtifacts(guid)) return true
  return useInstanceStore().hasPermission('artifact.'+artifactType+'.view', guid)
}

const canViewHeatmap = (globalUniqueIdentifier= undefined) => {
  return canViewArtifactType('heatmap', globalUniqueIdentifier)
}

const canViewLongTimeExposure = (globalUniqueIdentifier= undefined) => {
  return canViewArtifactType('longTimeExposure', globalUniqueIdentifier)
}

const canViewLongTimeExposureAnnotation = (globalUniqueIdentifier= undefined) => {
  return canViewArtifactType('longTimeExposureAnnotation', globalUniqueIdentifier)
}

const canViewArchivedStillFrame = (globalUniqueIdentifier= undefined) => {
  return canViewArtifactType('singleFrame', globalUniqueIdentifier)
}

const canViewStillFrame = (globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  return useInstanceStore().hasAnyPermission(['recordings.view.frame', 'streaming.recording.view'], guid)
}

const canViewRegionsHelper = (globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  return useInstanceStore().hasPermission('regions.view', guid)
}
const canViewAllIncidents = (globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  return useInstanceStore().hasPermission('incidents.view', guid)
}

const canViewIncidentType = (incidentType, globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  if(canViewAllIncidents(guid)) return true
  return useInstanceStore().hasPermission('incidents.'+incidentType+'.view', guid)
}

const canViewDeadAnimalsHelper = (globalUniqueIdentifier= undefined) => {
  return canViewIncidentType('deadAnimal', globalUniqueIdentifier)
}

const canTriggerDeadAnimals = (globalUniqueIdentifier= undefined) => {
  const guid = activeGUID(globalUniqueIdentifier)
  if(!isAuthenticated(guid)) return false
  return useInstanceStore().hasAnyPermission(['deadAnimals.manage', 'deadAnimals.manage.advanced'], guid)
}


export { canViewArchivedStillFrame, canViewDeadAnimalsHelper, canTriggerDeadAnimals, canViewHeatmap, canViewLongTimeExposure, canViewLongTimeExposureAnnotation, canViewArtifactType, canViewCameras, canViewStillFrame, canViewRegionsHelper, canViewAllIncidents, canViewIncidentType }
