// eslint-disable-next-line no-unused-vars
const DEV_PB_URL = 'https://dev.pig-brother.com'
// eslint-disable-next-line no-unused-vars
const STAGING_PB_URL = 'https://staging.pig-brother.com'
// eslint-disable-next-line no-unused-vars
const PREVIEW_PB_URL = 'https://preview.pig-brother.com'
// eslint-disable-next-line no-unused-vars
const PROD_PB_URL = 'https://pig-brother.com'
const DEV_DC_URL = 'https://dev.data-collector.vetvise.com'
const STAGING_DC_URL = 'https://staging.data-collector.vetvise.com'
const PREVIEW_DC_URL = 'https://preview.data-collector.vetvise.com'
const PROD_DC_URL = 'https://data-collector.vetvise.com'


const LOCAL_URL = 'http://localhost:8080'
function isInDataCollectorMode() {
  if(window.location.origin === LOCAL_URL) return false;
  return [DEV_DC_URL, STAGING_DC_URL, PREVIEW_DC_URL, PROD_DC_URL].includes(window.location.origin);
}



export { isInDataCollectorMode }
