<script setup>
import {
  humanReadableDuration,
  humanReadableTimestamp,
} from '@/composables/datetime';
import { getAge } from '@/composables/incidents/incidentTimeHelper';
import { useInstanceStore } from '@/store/backendInstances';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { writeToClipboard } from '@/composables/clipboard';
import { useToast } from 'vue-toastification';

const props = defineProps({ incident: { type: Object, default: () => ({}) } });

const { t } = useI18n({});
const instanceStore = useInstanceStore();
const toast = useToast();

const componentType = computed(() => {
  return props.incident.identifier.componentType;
});

const statusChipText = computed(() => {
  return t('incidents.healthCheck.component.' + componentType.value);
});

const showFailures = ref(false);

async function copyComponentId(e) {
  const copyResult = writeToClipboard(props.incident.identifier.componentId);
  if (copyResult) {
    toast.success(t('toast.copied_to_clipboard'));
  } else {
    toast.error(t('toast.copy_failed'));
  }
  e.stopPropagation();
}
</script>

<template>
  <v-layout-card>
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-row class="align-center">
      <v-card-title> {{ $t(`incidents.${incident.type}.title`) }}</v-card-title>
    </v-row>
    <v-col class="pb-0">
      <v-chip>
        {{ statusChipText }}
      </v-chip>
    </v-col>
    <v-card-text class="pb-0">
      <v-list>
        <v-list-item
          prepend-icon="mdi-state-machine"
          :title="$t('incidents.healthCheck.state.title')"
          :subtitle="$t(`incidents.healthCheck.state.${incident.state}`)"
        />

        <v-list-item
          prepend-icon="mdi-identifier"
          append-icon="mdi-content-copy"
          :title="$t('incidents.healthCheck.component.id')"
          :subtitle="incident.identifier.componentId"
          @click="copyComponentId"
        />

        <v-list-item
          v-if="componentType === 'camera'"
          prepend-icon="mdi-video"
          append-icon="mdi-open-in-new"
          :title="$t('incidents.healthCheck.titles.camera')"
          :subtitle="incident.messagePayload.prettyName"
          :to="`/instance/${instanceStore.selectedInstanceId}/live/${props.incident.identifier.componentId}`"
          target="_blank"
        />
        <v-list-item
          v-if="
            componentType !== 'camera' && incident.messagePayload.prettyName
          "
          prepend-icon="mdi-alphabetical-variant"
          :title="$t('incidents.healthCheck.titles.name')"
          :subtitle="incident.messagePayload.prettyName"
        />

        <v-list-item
          prepend-icon="mdi-clock-start"
          :title="$t('incidents.healthCheck.titles.firstDetection')"
          :subtitle="humanReadableTimestamp(incident.firstEventTimestamp)"
        />

        <v-list-item
          prepend-icon="mdi-clock-end"
          :title="$t('incidents.healthCheck.titles.lastDetection')"
          :subtitle="humanReadableTimestamp(incident.lastEventTimestamp)"
        />

        <v-list-item
          :title="$t('incidents.healthCheck.titles.duration')"
          prepend-icon="mdi-timelapse"
          :subtitle="
            humanReadableDuration(
              getAge(props.incident),
              undefined,
              ['d', 'h'],
              true
            )
          "
        />
      </v-list>

      <v-divider class="my-4" />

      <v-label class="mt-4">
        {{ $t("incidents.healthCheck.titles.lastFailures") }}
      </v-label>

      <v-list>
        <v-list-item
          prepend-icon="mdi-alert"
          :title="$t('incidents.healthCheck.failures.failed')"
          :subtitle="`${$t(
            'incidents.healthCheck.failures.failedTestsMessage',
            {
              failed: incident.messagePayload.latestFailures.failed,
              outOf: incident.messagePayload.latestFailures.outOf,
            }
          )}
          `"
        />

        <v-list-item
          v-if="incident.messagePayload.occurredFailures.length > 0"
          prepend-icon="mdi-eye"
          :append-icon="showFailures ? 'mdi-chevron-up' : 'mdi-chevron-down'"
          :title="` ${
            showFailures
              ? $t('incidents.healthCheck.failures.hidefailureMessages')
              : $t('incidents.healthCheck.failures.showfailureMessages')
          }`"
          :subtitle="`
            ${incident.messagePayload.occurredFailures.length}
            ${$t('incidents.healthCheck.failures.failureMessages')}
          `"
          @click="showFailures = !showFailures"
        />
      </v-list>

      <v-expand-transition>
        <div
          v-show="showFailures"
          class="my-1"
        >
          <pre><code>{{
            JSON.stringify(
              incident.messagePayload.occurredFailures,
              null,
              2
            ) }}</code></pre>
        </div>
      </v-expand-transition>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot name="genericActions" />
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped></style>
