import Syncable from '@/syncing/Syncable.js';
import { useIncidentStore } from '@/store/incident.js';
import { useInstanceStore } from '@/store/backendInstances.js';

class IncidentsAssignedToMeSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 5, IncidentsAssignedToMeSyncable.getSyncableHandle(), true, true)
  }

  hasInitialData() {
    return !!useIncidentStore().getIncidentsAssignedToMe(this.globalUniqueIdentifier)
  }

  async workload() {
    const currentAccountId = useInstanceStore().getAccountIdForInstance(this.globalUniqueIdentifier)

    const payload = {
      query: [
        { key: 'assignedTo.entityId', operator: '==', value: currentAccountId },
        { key: 'state', operator: '!=', value: 'closed' },
      ],
      projection: 'count',
    }
    const newValue = await useIncidentStore().queryIncidents(payload, this.globalUniqueIdentifier)
    if (newValue === null) return
    if (newValue === useIncidentStore().getIncidentsAssignedToMe(this.globalUniqueIdentifier)) return

    useIncidentStore().incidentsAssignedToMe[this.globalUniqueIdentifier] = newValue
  }
  static getSyncableHandle() {
    return 'incidents-assigned-to-me'
  }
}

export default IncidentsAssignedToMeSyncable
