import i18n from '../../plugins/i18n'
import { useInstanceStore } from '@/store/backendInstances';
import { EQ, GT, GTE, IN, LT, LTE, NEQ, NIN } from '@/composables/filters/operators';
import {
  getAvailableProperties,
  getDataType,
  getOperators,
  getOptions, getOptionTitle,
  getPropertyTitle, getSortableProperties,
} from '@/composables/filters/filterHelpers';

const { t } = i18n.global


const properties = {
  status: {
    title: t('filters.artifacts.properties.status.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'autocomplete',
    options: [
      {
        id: 'done',
        title: t('filters.artifacts.properties.status.options.done.title'),
        enabled: () => true,
      },
      {
        id: 'archived',
        title: t('filters.artifacts.properties.status.options.archived.title'),
        enabled: () => true,
      },
      {
        id: 'scheduled',
        title: t('filters.artifacts.properties.status.options.scheduled.title'),
        enabled: () => true,
      },
      {
        id: 'pending',
        title: t('filters.artifacts.properties.status.options.pending.title'),
        enabled: () => true,
      },
      {
        id: 'error',
        title: t('filters.artifacts.properties.status.options.error.title'),
        enabled: () => true,
      },
      {
        id: 'failed',
        title: t('filters.artifacts.properties.status.options.failed.title'),
        enabled: () => true,
      },
    ],
  },
  'data.cameraId': {
    title: t('filters.artifacts.properties.data.cameraId.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'camera',
  },
  type: {
    dataType: 'autocomplete',
    title: t('filters.artifacts.properties.type.title'),
    operators: [EQ, NEQ, IN, NIN ],
    options: [
      {
        id: 'heatmap',
        title: t('filters.artifacts.properties.type.options.heatmap.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.heatmap.view']),
      },
      {
        id: 'longTimeExposure',
        title: t('filters.artifacts.properties.type.options.longTimeExposure.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.longTimeExposure.view']),
      },
      {
        id: 'longTimeExposureAnnotation',
        title: t('filters.artifacts.properties.type.options.longTimeExposureAnnotation.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.longTimeExposureAnnotation.view']),
      },
      {
        id: 'backup.influxdb',
        title: t('filters.artifacts.properties.type.options.backup.influxdb.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.backup.influxdb.view']),
      },
      {
        id: 'backup.mongodb',
        title: t('filters.artifacts.properties.type.options.backup.mongodb.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.backup.mongodb.view']),
      },
      {
        id: 'heatmapDiff',
        title: t('filters.artifacts.properties.type.options.heatmapDiff.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.heatmapDiff.view']),
      },
      {
        id: 'influxData',
        title: t('filters.artifacts.properties.type.options.influxData.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.influxData.view']),
      },
      {
        id: 'backup.posex',
        title: t('filters.artifacts.properties.type.options.backup.posex.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.backup.posex.view']),
      },
      {
        id: 'raw_frames',
        title: t('filters.artifacts.properties.type.options.raw_frames.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.raw_frames.view']),
      },
      {
        id: 'recording',
        title: t('filters.artifacts.properties.type.options.recording.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.recording.view']),
      },
      {
        id: 'backup.recording',
        title: t('filters.artifacts.properties.type.options.backup.recording.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.backup.recording.view']),
      },
      {
        id: 'singleFrame',
        title: t('filters.artifacts.properties.type.options.singleFrame.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.singleFrame.view']),
      },
      {
        id: 'canvas',
        title: t('filters.artifacts.properties.type.options.canvas.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.canvas.view']),
      },
      {
        id: 'canvasTimelapse',
        title: t('filters.artifacts.properties.type.options.canvasTimelapse.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['artifact.view', 'artifact.canvasTimelapse.view']),
      },
    ],
  },
  jobName: {
    title: t('filters.artifacts.properties.jobName.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'String',
  },
  'requestedBy.entityId': {
    title: t('filters.artifacts.properties.requested_by.entity_id.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'String',
  },
  'requestedBy.entityType': {
    title: t('filters.artifacts.properties.requested_by.entity_type.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'String',
  },

  timestamp: {
    title: t('filters.artifacts.properties.timestamp.title'),
    operators: [GTE, LTE, EQ, GT, LT],
    dataType: 'timestamp',
  },
  artifactId: {
    dataType: 'String',
    title: t('filters.artifacts.properties.artifactId.title'),
    operators: [EQ, NEQ, IN, NIN ],
  },
  priority: {
    dataType: 'Number',
    title: t('filters.artifacts.properties.priority.title'),
    operators: [EQ, IN, NIN, NEQ, LT, LTE, GT, GTE],
  },
  size: {
    dataType: 'Number',
    title: t('filters.artifacts.properties.size.title'),
    operators: [EQ, NEQ, LT, LTE, GT, GTE],
  },
}

const sortableProperties = [
  'timestamp',
  'size',
  'creationStart',
  'creationEnd',
  'data.cameraId',
  'type',
  'jobName',
  'status',
]

export default {
  getOptionTitle: (propertyId, optionId) => getOptionTitle(properties, propertyId, optionId),
  getPropertyTitle: propertyId => getPropertyTitle(properties, propertyId),
  getAvailableProperties: () => getAvailableProperties(properties),
  getOperators: propertyId => getOperators(properties, propertyId),
  getDataType: propertyId => getDataType(properties, propertyId),
  getOptions: propertyId => getOptions(properties, propertyId),
  getSortableProperties: () => getSortableProperties(sortableProperties, properties),
}
