<script setup>

import { computed, onMounted } from 'vue';
import { useArtifactStore } from '@/store/artifacts';
import HeatmapPayload from '@/components/artifacts/payloads/HeatmapPayload.vue';
import GenericImagePayload from '@/components/artifacts/payloads/GenericImagePayload.vue';
import GenericFilePayload from '@/components/artifacts/payloads/GenericFilePayload.vue';
import VideoPayload from '@/components/artifacts/payloads/VideoPayload.vue';
const artifactStore = useArtifactStore()

const props = defineProps({ artifactId: { type: String, default:'' }, fullScreen: { type: Boolean, default: false } })

const artifact = computed(() => {
  return artifactStore.artifacts[props.artifactId]
})

const isDownloaded = computed(() => {
  if(!artifact.value || !artifact.value.controlRecord) return false
  return artifact.value.controlRecord.downloaded
})

const isDownloading = computed(() => {
  if(!artifact.value || !artifact.value.controlRecord) return false
  return artifact.value.controlRecord.downloading
})

const artifactType = computed(() => {
  if(!artifact.value || !artifact.value.data) return ''
  return artifact.value.data.type
})

const showHeatmapPayload = computed(() => {
  return artifactType.value === 'heatmap'
})

const showGenericImagePayload = computed(() => {
  return ['longTimeExposure', 'singleFrame', 'longTimeExposureAnnotation', 'canvas'].includes(artifactType.value)
})

const showVideoPayload = computed(() => {
  return ['recording', 'canvasTimelapse'].includes(artifactType.value)
})

const showGenericFilePayload = computed(() => {
  return (!showGenericImagePayload.value && !showVideoPayload.value && !showHeatmapPayload.value)
})

onMounted(async () => {

})
</script>

<template>
  <v-container class="justify-center ma-0 pa-0">
    <HeatmapPayload
      v-if="showHeatmapPayload"
      :artifact-id="artifactId"
      :payload="artifact.payload"
      :is-downloaded="isDownloaded"
      :artifact-data="artifact.data"
      :full-screen="fullScreen"
      :is-downloading="isDownloading"
    />
    <GenericImagePayload
      v-if="showGenericImagePayload"
      :artifact-id="artifactId"
      :payload="artifact.payload"
      :is-downloaded="isDownloaded"
      :artifact-data="artifact.data"
      :full-screen="fullScreen"
      :is-downloading="isDownloading"
    />
    <GenericFilePayload
      v-if="showGenericFilePayload"
      :artifact-id="artifactId"
      :payload="artifact.payload"
      :is-downloaded="isDownloaded"
      :artifact-data="artifact.data"
      :full-screen="fullScreen"
      :is-downloading="isDownloading"
    />
    <VideoPayload
      v-if="showVideoPayload"
      :artifact-id="artifactId"
      :payload="artifact.payload"
      :is-downloaded="isDownloaded"
      :artifact-data="artifact.data"
      :full-screen="fullScreen"
      :is-downloading="isDownloading"
    />
  </v-container>
</template>

<style scoped>

</style>
