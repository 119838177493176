<script setup>
import { DateTime } from 'luxon';
import { computed, ref } from 'vue';
import { humanReadableDuration } from '@/composables/datetime';

const timestamp = defineModel({ type: DateTime, default: null })
defineEmits(['close'])
const interval = ref(1000 * 60)
const timeJumperMenu = ref(false)
const intervals = [
  1000 * 60,
  1000 * 60 * 5,
  1000 * 60 * 10,
  1000 * 60 * 30,
  1000 * 60 * 60,
  1000 * 60 * 60 * 3,
  1000 * 60 * 60 * 6,
  1000 * 60 * 60 * 12,
  1000 * 60 * 60 * 24,
  1000 * 60 * 60 * 24 * 3,
  1000 * 60 * 60 * 24 * 7,
]
const availableIntervals = computed(() => {
  return intervals.map(interval => ({ value: interval, title: humanReadableDuration(interval) }))
})

function jumpForward() {
  timestamp.value = timestamp.value.plus({ millisecond: interval.value })
}

function jumpBackward() {
  timestamp.value = timestamp.value.minus({ millisecond: interval.value })
}
</script>

<template>
  <v-card
    variant="elevated"
    class="position-absolute pt-1"
    style="bottom: 8px; right: 50%; transform: translate(+50%); z-index: 10"
  >
    <v-row class="ma-0 mb-0 align-center justify-space-between">
      <v-card-subtitle>
        {{ $t('canvas.time_jump') }}
      </v-card-subtitle>
      <v-btn
        class="mx-2 mt-2"
        icon="mdi-close"
        size="xx-small"
        variant="outlined"
        @click="$emit('close')"
      />
    </v-row>

    <v-card-text>
      <v-row
        class="align-center justify-center"
        style="min-width: 250px"
      >
        <v-btn
          class="ma-1"
          variant="outlined"
          icon="mdi-skip-backward"
          size="small"
          @click="jumpBackward"
        />
        <v-btn
          class="ma-1 rounded-pill"
          variant="outlined"
        >
          {{ humanReadableDuration(interval) }}
          <v-menu
            v-model="timeJumperMenu"
            activator="parent"
          >
            <v-list>
              <v-list-item
                v-for="i in availableIntervals"
                :key="i.value"
                :value="i.value"
                :title="i.title"
                @click="interval = i.value;"
              />
            </v-list>
          </v-menu>
        </v-btn>
        <v-btn
          class="ma-1"
          variant="outlined"
          icon="mdi-skip-forward"
          size="small"
          @click="jumpForward"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
