import { useSyncingHandlersStore } from '@/store/syncingHandlers.js';
import { useInstanceStore } from '@/store/backendInstances.js';
import { useLogsStore } from '@/store/logs.js';

const heartbeatInterval = 1000 * 5;
const heartbeatStaleTimeout = 1000 * 5 * 3;

class Syncable {
  constructor(globalUniqueIdentifier, interval, handle, needsOnline = true, needsLoggedIn = true) {
    this.globalUniqueIdentifier = globalUniqueIdentifier;
    this.interval = interval;
    this.handle = handle;
    this.needsOnline = needsOnline;
    this.needsLoggedIn = needsLoggedIn;
    this.inactive = false
  }


  markAsInactive() {
    this.inactive = true;
    return this.setLastSync()
  }

  markAsActive() {
    this.inactive = false;
  }

  startHeartbeat() {
    useSyncingHandlersStore().updateHeartbeat(this.globalUniqueIdentifier, this.handle)
    if (this.heartbeat) clearInterval(this.heartbeat);
    this.heartbeat = setInterval(() => {
      useSyncingHandlersStore().updateHeartbeat(this.globalUniqueIdentifier, this.handle)
    }, heartbeatInterval)
  }

  stopHeartbeat() {
    if (this.heartbeat) clearInterval(this.heartbeat);
  }

  setLoading(loading) {
    useSyncingHandlersStore().setLoading(this.globalUniqueIdentifier, this.handle, loading)
  }

  async workload() {
    throw new Error('Not implemented')
  }

  isIntervalMet() {
    const lastSync = useSyncingHandlersStore().getLastSync(this.globalUniqueIdentifier, this.handle);
    return lastSync + this.interval <= Date.now();
  }

  isLoading() {
    return useSyncingHandlersStore().isLoading(this.globalUniqueIdentifier, this.handle)
  }

  getLastHeartbeat() {
    return useSyncingHandlersStore().getLastHeartbeat(this.globalUniqueIdentifier, this.handle)
  }

  setLastSync() {
    if (this.inactive)
      return useSyncingHandlersStore().setLastSync(this.globalUniqueIdentifier, this.handle, 0)
    return useSyncingHandlersStore().setLastSync(this.globalUniqueIdentifier, this.handle)
  }

  satisfiesPreconditions() {
    const instanceStore = useInstanceStore()
    if(this.needsOnline) {
      try {
        const isOnline = instanceStore.isOnline(this.globalUniqueIdentifier)
        if (isOnline === false) return false;
      } catch(e) {
        useLogsStore().addLogEntry({
          message: 'Cannot check online status',
          tag: 'SYNC',
          globalUniqueIdentifier: this.globalUniqueIdentifier,
          level: 'INFO',
          error: e,
        })
      }
    }
    if (this.needsOnline && instanceStore.isOnline(this.globalUniqueIdentifier) === false) return false;
    if (this.needsLoggedIn && !instanceStore.isLoggedIn(this.globalUniqueIdentifier)) return false;
    return true;
  }


  async sync(ignoreInterval = false) {
    if (!this.isIntervalMet() && !ignoreInterval) {
      return
    }
    if (this.isLoading() === true && this.getLastHeartbeat() && this.getLastHeartbeat() + heartbeatStaleTimeout > Date.now()) {
      return;
    }
    if (!this.satisfiesPreconditions()) {
      return;
    }
    this.setLoading(true);
    try {
      useLogsStore().addLogEntry({
        message: `Syncing ${this.handle}`,
        tag: 'SYNC',
        globalUniqueIdentifier: this.globalUniqueIdentifier,
        level: 'INFO',
      })
      this.startHeartbeat();
      this.workloadPromise = this.workload()
      await this.workloadPromise;
      this.setLastSync()
      this.workloadPromise = null;
      this.setLoading(false);
    } finally {
      this.stopHeartbeat();
      this.workloadPromise = null;
    }
  }


  hasInitialData() {
    throw new Error('Not implemented')
  }

  async ensureInitialDataExists() {
    if (this.hasInitialData()) return;
    return this.sync(true)
  }

  static getSyncableHandle() {
    throw new Error('Not implemented')
  }
}

export default Syncable;
