import Syncable from '@/syncing/Syncable.js';
import _ from 'lodash';
import { useCamerasStore } from '@/store/cameras.js';
import { useInstanceStore } from '@/store/backendInstances.js';

class CamerasSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 10, CamerasSyncable.getSyncableHandle(), true, true)
  }

  satisfiesPreconditions() {
    if(useInstanceStore().instances[this.globalUniqueIdentifier].instanceType !== 'barn') return false
    return super.satisfiesPreconditions();
  }

  hasInitialData() {
    const data = useCamerasStore().getCameras(this.globalUniqueIdentifier)
    if (!data) return false
    if (data.length === 0) return false
  }

  async workload() {
    const cameras = await useCamerasStore().loadCameras(this.globalUniqueIdentifier)
    if (cameras === null) return false;
    const currentCameras = useCamerasStore().getCameras(this.globalUniqueIdentifier)
    if (currentCameras && _.isEqual(currentCameras, cameras)) return;
    useCamerasStore().cameras[this.globalUniqueIdentifier] = cameras
  }
  static getSyncableHandle() {
    return 'cameras'
  }
}

export default CamerasSyncable
