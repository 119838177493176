<script setup>
import { loadGenericMeasurement } from '@/composables/charts';
import CameraBaseChart from '@/components/charts/CameraBaseChart.vue';
const props = defineProps(
  {
    cameraIds: { type: Array, default: () => [] },
    aggregate: { type: Boolean, default: false },
    measurement: { type: String, default: null },
    field: { type: String, default: null },
    title: { type: String, default: '' },
    subtitle: { type: String, default: '' },
    disableControlRow: { type: Boolean, default: false },
    chainTimeSetup: { type: Boolean, default: false },
    xAggregation: { type: String, default: 'mean' },
    skipTimeResolution: { type: Boolean, default: false },
  },
)

const timeSetup = defineModel('timeSetup', { required: true, type: Object })
async function dataLoader(cameraIds, timeSetup) {
  const data = await loadGenericMeasurement({
    cameraIds: cameraIds,
    timeResolution : timeSetup.timeResolution,
    timeQuery: { start: timeSetup.start, end: timeSetup.end },
    measurement: props.measurement,
    field: props.field,
    aggregate: props.aggregate,
    chartType: 'line',
    xAggregation: props.xAggregation,
    skipTimeResolution: props.skipTimeResolution,
  })
  return data
}


</script>

<template>
  <CameraBaseChart
    v-model:time-setup="timeSetup"
    :title="props.title"
    :subtitle="props.subtitle"
    :disable-control-row="disableControlRow"
    :data-loader="dataLoader"
    :camera-ids="cameraIds"
    :chain-time-setup="chainTimeSetup"
  />
</template>
