import Syncable from '@/syncing/Syncable.js';
import { useInstanceStore } from '@/store/backendInstances.js';

class VersionSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 60, VersionSyncable.getSyncableHandle(), true, false)
  }


  satisfiesPreconditions() {
    return super.satisfiesPreconditions();
  }

  hasInitialData() {
    return !!useInstanceStore().instances[this.globalUniqueIdentifier].version
  }

  async workload() {
    await useInstanceStore().loadVersion(this.globalUniqueIdentifier)
  }

  static getSyncableHandle() {
    return 'version'
  }
}

export default VersionSyncable
