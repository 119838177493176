<script setup>
import { computed, onMounted, ref } from 'vue';
import { useRegionStore } from '@/store/regions';

const regionStore = useRegionStore()
defineProps({
  modelValue: { type: Array, default: () => [] },
  label: { type: String, required: true },
  rules: { type: Array, default: () => [] },
  multiple: { type: Boolean, default: true },
  readOnly: { type: Boolean, default: false },
  disabled: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue'])
const regionSpecifications = computed(() => regionStore.getRegionSpecifications())

const availableRegionSpecifications = computed(() => {
  if(regionSpecifications.value === null) return []
  const specificationIds = Object.keys(regionSpecifications.value)
  return specificationIds.map(specificationId => {
    return {
      value: specificationId,
      title: regionSpecifications.value[specificationId].regionType + ' -> ' + specificationId,
    }
  })
})

const loading = ref(false)
onMounted(async () => {
  loading.value = true
  regionStore.keepRegionsLoaded()
  loading.value = false
})

</script>

<template>
  <v-autocomplete
    :model-value="modelValue"
    :label="label"
    :rules="rules"
    class="ma-2"
    variant="outlined"
    :items="availableRegionSpecifications"
    :multiple="multiple"
    :chips="true"
    :readonly="readOnly"
    :disabled="disabled"
    @update:model-value="emit('update:modelValue', $event)"
  />
</template>

<style scoped>

</style>
