class CanvasElementSourceProvider {
  constructor({ cameraId, scale, timestamp, height, elementProviderReference, router }, providerType = undefined) {
    this.cameraId = cameraId;
    this.scale = scale;
    this.timestamp = timestamp;
    this.height = height;
    this.router = router;
    this.elementProviderReference = elementProviderReference;
    this.sources = {};
    this.providerType = providerType;
    this.tooltipText = () => null;
  }

  setTimestamp(timestamp) {
    this.timestamp = timestamp;
    this.sources = {}
  }

  destroy() {
    this.sources = {};
  }

  setScale(scale) {
    this.scale = scale;
  }

  // eslint-disable-next-line no-unused-vars
  fetchSource(isInView) {
    throw new Error('fetchSource not implemented')
  }

  getAppropriateSource(height) {
    const maximumAvailableHeight = Object.keys(this.sources).length > 0 ? Math.max(...Object.keys(this.sources).map(height => parseInt(height))) : null;
    if (maximumAvailableHeight && maximumAvailableHeight >= height) return {
      appropriateSource: this.sources[maximumAvailableHeight], appropriateSourceHeight: maximumAvailableHeight,
    };
    if (this.sources[height]) return { appropriateSource: this.sources[height], appropriateSourceHeight: height };
    return { appropriateSource: null, appropriateSourceHeight: null };
  }

  getTargetHeight() {
    return this.height * this.scale;
  }

  getQuantizedHeight() {
    const targetHeight = this.getTargetHeight();
    const heights = [1080, 810, 540, 270, 100]
    let difference = Number.MAX_SAFE_INTEGER
    let closestHeight
    for (const height of heights) {
      let currentDifference = Math.abs(height - targetHeight)
      if (currentDifference < difference) {
        difference = currentDifference
        closestHeight = height
      }
    }
    return closestHeight
  }

  handleDoubleClick(ev) {
    ev.evt.preventDefault()
  }

  handleClick(ev) {
    ev.evt.preventDefault()
  }

  handleContextMenu(ev) {
    ev.evt.preventDefault()
  }
}

export default CanvasElementSourceProvider;
