import Konva from 'konva'
import { reactive } from 'vue'
import { useLogsStore } from '@/store/logs.js'

class CanvasPluginProvider {

  constructor({
    enabled = false,
    // eslint-disable-next-line no-unused-vars
    canvasId = null,
    // eslint-disable-next-line no-unused-vars
    elementProvider = null,
    stageWidth = 0,
    stageHeight = 0,
    timestamp = 0,
    elementPositions = {},
    config = {},
    stage = null,
    contextMenuHandler = () => {
    },
    tooltipEnable = () => {
    },
    tooltipDisable = () => {
    },
    router = null,
  }, providerType, needsReloadOnTimestampChange = true, needsReRenderOnTimestampChange = true) {
    this.providerType = providerType
    this.stage = stage
    this.stageWidth = stageWidth
    this.stageHeight = stageHeight
    this.timestamp = timestamp
    this.elementPositions = elementPositions
    this.config = config
    this.contextMenuHandler = contextMenuHandler
    this.tooltipEnable = tooltipEnable
    this.tooltipDisable = tooltipDisable
    this.group = new Konva.Group()
    this.layer = new Konva.Layer()
    this.layer.add(this.group)
    this.needsReloadOnTimestampChange = needsReloadOnTimestampChange
    this.needsReRenderOnTimestampChange = needsReRenderOnTimestampChange
    this.reactiveProperties = reactive({
      loading: false,
      enabled: enabled,
    })
    if (this.reactiveProperties.enabled) {
      this.layer.show()
    } else {
      this.layer.hide()
    }

    this.router = router
    this.needsToBeReRendered = true
    this.needsToBeReloaded = true
    this.render()

  }

  fetchInformation() {
    throw Error('Has to be implemented by Subclass')
  }

  draw() {
    throw Error('Has to be implemented by Subclass')
  }

  render() {
    if (!this.needsToBeReRendered) return
    if (!this.isEnabled()) return
    if (this.reactiveProperties.loading) {
      this.fetchInformationAbortController.abort()
      this.fetchInformationPromise = null
      this.fetchInformationAbortController = null
    }

    if (this.needsToBeReloaded) {
      this.needsToBeReRendered = false
      this.needsToBeReloaded = false
      this.reactiveProperties.loading = true
      const fetchInformationResult = this.fetchInformation()
      this.fetchInformationPromise = fetchInformationResult[0]
      this.fetchInformationAbortController = fetchInformationResult[1]
      this.fetchInformationPromise.then(() => {
        this.draw()
        this.render()
      }).catch(error => {
        useLogsStore().addLogEntry({
          message: 'Error while fetching Plugin information',
          tag: 'CANVAS',
          level: 'ERROR',
          error: error,
        })
      }).finally(() => {
        this.fetchInformationPromise = null
        this.fetchInformationAbortController = null
        this.reactiveProperties.loading = false
      })
    } else {
      this.needsToBeReRendered = false
      this.needsToBeReloaded = false
      this.draw()
    }


  }

  getLayer() {
    return this.layer
  }

  enable() {
    if (this.reactiveProperties.enabled) return
    this.reactiveProperties.enabled = true
    this.needsToBeReRendered = true
    this.needsToBeReloaded = true
    this.render()
    this.layer.show()
  }

  disable() {
    this.reactiveProperties.enabled = false
    this.layer.hide()
  }

  isEnabled() {
    return this.reactiveProperties.enabled
  }

  rotate(rotation) {
    this.group.rotation(rotation)
    this.layer.draw()
  }

  updateTimestamp(timestamp) {
    this.timestamp = timestamp
    if (this.needsReRenderOnTimestampChange) this.needsToBeReRendered = true
    if (this.needsReloadOnTimestampChange) this.needsToBeReloaded = true
    this.render()
  }

  forceUpdate() {
    this.needsToBeReRendered = true
    this.needsToBeReloaded = true
    this.render()
  }


  // eslint-disable-next-line no-unused-vars
  doesConfigChangeNeedRerender(config) {
    throw Error('Has to be implemented by Subclass')
  }


  // eslint-disable-next-line no-unused-vars
  doesConfigChangeNeedReload(config) {
    throw Error('Has to be implemented by Subclass')
  }

  updateConfig(config) {
    this.needsToBeReloaded = this.doesConfigChangeNeedReload(config)
    this.needsToBeReRendered = this.doesConfigChangeNeedRerender(config) || this.needsToBeReloaded
    this.config = config
    if (this.needsToBeReRendered) {
      this.render()
    }

  }

  destroyPlugin() {
    this.group.destroy()
    this.layer.destroy()
  }
}

export default CanvasPluginProvider
