<script setup>
import { useDisplay } from 'vuetify';
import { computed, ref } from 'vue';

const { t } = useI18n({})

import {
  canViewArchivedStillFrame,
  canViewHeatmap,
  canViewLongTimeExposure,
  canViewLongTimeExposureAnnotation,
  canViewStillFrame,
} from '@/composables/permissionHelper';
import stillFrameIconUrl from '@/assets/canvasElementProviderIcons/stillFrame.png';
import heatmapIconUrl from '@/assets/canvasElementProviderIcons/heatmap.png';
import lteIconUrl from '@/assets/canvasElementProviderIcons/lte.png';
import { useI18n } from 'vue-i18n';
import CanvasOverlayPicker from '@/components/canvas/Overlays/CanvasOverlayPicker.vue';

const { mobile, width, height, smAndUp } = useDisplay()

const props = defineProps({
  elementProvider: { type: String, default: 'stillFrame' },
  pluginConfig: { type: Object, default: () => ({}) },
})

const availableElementProviders = computed(() => {
  const providers = [
    {
      id: 'stillFrame',
      hasPermission: canViewStillFrame(),
      sprite: stillFrameIconUrl,
      title: t('canvas.element_providers.stillFrame'),
    },
    {
      id: 'heatmap',
      hasPermission: canViewHeatmap(),
      sprite: heatmapIconUrl,
      title: t('canvas.element_providers.heatmap'),
    },
    {
      id: 'longTimeExposure',
      hasPermission: canViewLongTimeExposure(),
      sprite: lteIconUrl,
      title: t('canvas.element_providers.longTimeExposure'),
    },
    {
      id: 'longTimeExposureAnnotation',
      hasPermission: canViewLongTimeExposureAnnotation(),
      sprite: lteIconUrl,
      title: t('canvas.element_providers.longTimeExposureAnnotation'),
    },
    {
      id: 'archivedStillFrame',
      hasPermission: canViewArchivedStillFrame(),
      sprite: stillFrameIconUrl,
      title: t('canvas.element_providers.archivedStillFrame'),
    },
  ]
  return providers.filter(({ hasPermission }) => hasPermission)
})

const currentlyActiveImageSourceSprite = computed(() => {
  if (!props.elementProvider) return stillFrameIconUrl
  switch (props.elementProvider) {
  case 'stillFrame':
    return stillFrameIconUrl
  case 'heatmap':
    return heatmapIconUrl
  case 'longTimeExposure':
    return lteIconUrl
  case 'longTimeExposureAnnotation':
    return lteIconUrl
  case 'archivedStillFrame':
    return stillFrameIconUrl
  }
  return stillFrameIconUrl
})

defineEmits([
  'update:elementProvider',
  'update:pluginConfig',
  'openShareDialog',
])

const menuExpanded = ref(false)

</script>

<template>
  <v-card
    class="position-absolute ma-4"
    variant="elevated"
    style="bottom: 0; border-radius: 16px"
    width="80px"
    @click="menuExpanded = true"
  >
    <v-img
      :src="currentlyActiveImageSourceSprite"
      aspect-ratio="1"
      class="align-end"
    >
      <h5 class="text-grey-darken-4 text-center">
        Image
      </h5>
    </v-img>

    <v-dialog
      v-if="!smAndUp"
      v-model="menuExpanded"
      :fullscreen="true"
      :open-delay="0"
      :open-on-hover="true"
      :close-on-content-click="false"
    >
      <v-layout-card>
        <v-btn
          variant="elevated"
          icon="mdi-share-variant"
          style=" position:absolute;  right:16px; top: 16px;"
          @click="$emit('openShareDialog')"
        />
        <CanvasOverlayPicker
          v-if="!mobile || height<=width"
          :plugin-config="pluginConfig"
          @update:plugin-config="$emit('update:pluginConfig', $event)"
        />
        <v-row
          :dense="true"
          :no-gutters="true"
          class="ma-0 pa-0"
        >
          <v-col
            class="ma-0 pa-0"
            :cols="mobile && height > width ? 5 : 'auto'"
          >
            <v-row
              :dense="true"
              :no-gutters="true"
            >
              <v-col
                v-for="availableElementProvider in availableElementProviders"
                :key="availableElementProvider.id"
              >
                <v-card
                  :variant="availableElementProvider.id === elementProvider ? 'outlined' : 'flat'"
                  class="rounded ma-2 justify-center"
                  width="100px"
                >
                  <v-img
                    aspect-ratio="1"
                    :src="availableElementProvider.sprite"
                    class="align-end"
                    @click="$emit('update:elementProvider', availableElementProvider.id)"
                  />
                  <v-card-subtitle
                    class="text-center"
                    style="white-space: normal; overflow: hidden; word-break: normal;"
                  >
                    {{ availableElementProvider.title }}
                  </v-card-subtitle>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="mobile && height > width"
            :cols="7"
          >
            <CanvasOverlayPicker
              :vertical="true"
              :plugin-config="pluginConfig"
              @update:plugin-config="$emit('update:pluginConfig', $event)"
            />
          </v-col>
        </v-row>
        <v-card-actions>
          <v-btn
            :block="true"
            variant="tonal"
            prepend-icon="mdi-chevron-left"
            @click="menuExpanded = false"
          >
            {{
              $t("general_interface.buttons.back")
            }}
          </v-btn>
        </v-card-actions>
      </v-layout-card>
    </v-dialog>

    <v-menu
      v-if="smAndUp"
      v-model="menuExpanded"
      :close-delay="400"
      :close-on-content-click="false"
      activator="parent"
      :open-on-hover="true"
      :open-delay="0"
      :location="mobile && height > width ? 'top start':'end bottom'"
    >
      <v-sheet
        :class="mobile && height > width ? 'my-2' : 'mx-2'"
        style="border-radius: 32px"
      >
        <v-btn
          variant="elevated"
          icon="mdi-share-variant"
          style=" position:absolute;  right:-16px; top: -16px;"
          @click="$emit('openShareDialog')"
        />
        <CanvasOverlayPicker
          v-if="!mobile || height<=width"
          :plugin-config="pluginConfig"
          @update:plugin-config="$emit('update:pluginConfig', $event)"
        />
        <v-row
          :dense="true"
          :no-gutters="true"
          class="ma-0 pa-0"
        >
          <v-col
            class="ma-0 pa-0"
            :cols="mobile && height > width ? 5 : 'auto'"
          >
            <v-row
              :dense="true"
              :no-gutters="true"
            >
              <v-col
                v-for="availableElementProvider in availableElementProviders"
                :key="availableElementProvider.id"
                class="pb-4"
              >
                <v-card
                  :elevation="availableElementProvider.id === elementProvider ? 20 : 0"
                  :variant="availableElementProvider.id === elementProvider ? 'elevated' : 'flat'"
                  class="ma-2 justify-center"
                  width="100px"
                  height="100%"
                  style="border-radius: 16px"
                >
                  <v-img
                    style="border-radius: 32px"
                    aspect-ratio="1"
                    :src="availableElementProvider.sprite"
                    class="align-end"
                    @click="$emit('update:elementProvider', availableElementProvider.id)"
                  />
                  <v-container class="ma-0 pa-0">
                    <v-card-subtitle
                      class="text-center"
                      style="white-space: normal; overflow: hidden; word-break: normal;"
                    >
                      {{ availableElementProvider.title }}
                    </v-card-subtitle>
                  </v-container>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            v-if="mobile && height > width"
            :cols="7"
          >
            <CanvasOverlayPicker
              :vertical="true"
              :plugin-config="pluginConfig"
              @update:plugin-config="$emit('update:pluginConfig', $event)"
            />
          </v-col>
        </v-row>
      </v-sheet>
    </v-menu>
  </v-card>
</template>

<style scoped>

</style>
