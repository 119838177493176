import { defineStore } from 'pinia'

export const useSyncingHandlersStore = defineStore('syncingHandlers', {
  state: () => ({
    handles: {},
  }),
  persist: false,
  share: {
    enable: true,
    initialize: true,
  },
  getters: {
    getLastHeartbeat() {
      return (globalUniqueIdentifier, handle) => {
        if (this.handles[globalUniqueIdentifier] && this.handles[globalUniqueIdentifier][handle])
          return this.handles[globalUniqueIdentifier][handle].lastHeartbeat
        return null
      }
    },
    getLastSync() {
      return (globalUniqueIdentifier, handle) => {
        if (this.handles[globalUniqueIdentifier] && this.handles[globalUniqueIdentifier][handle])
          return this.handles[globalUniqueIdentifier][handle].lastSync
        return null
      }
    },
    isLoading() {
      return (globalUniqueIdentifier, handle) => {
        if (this.handles[globalUniqueIdentifier] && this.handles[globalUniqueIdentifier][handle])
          return this.handles[globalUniqueIdentifier][handle].loading
        return false
      }
    },
  },
  actions: {
    updateHeartbeat(globalUniqueIdentifier, handle) {
      if (!this.handles[globalUniqueIdentifier]) this.handles[globalUniqueIdentifier] = {}
      if (!this.handles[globalUniqueIdentifier][handle]) this.handles[globalUniqueIdentifier][handle] = {
        lastHeartbeat: null,
        lastSync: null,
        loading: false,
      }
      this.handles[globalUniqueIdentifier][handle].lastHeartbeat = Date.now()
    },
    setLoading(globalUniqueIdentifier, handle, loading) {
      if (!this.handles[globalUniqueIdentifier]) this.handles[globalUniqueIdentifier] = {}
      if (!this.handles[globalUniqueIdentifier][handle]) this.handles[globalUniqueIdentifier][handle] = {
        lastHeartbeat: null,
        lastSync: null,
        loading: false,
      }
      this.handles[globalUniqueIdentifier][handle].loading = loading
    },

    setLastSync(globalUniqueIdentifier, handle, value=Date.now()) {
      if (!this.handles[globalUniqueIdentifier]) this.handles[globalUniqueIdentifier] = {}
      if (!this.handles[globalUniqueIdentifier][handle]) this.handles[globalUniqueIdentifier][handle] = {
        lastHeartbeat: null,
        lastSync: null,
        loading: false,
      }
      this.handles[globalUniqueIdentifier][handle].lastSync = value
    },


  },
})
