import { Buffer } from 'buffer/';

const buildDiscoveryObject = value => {
  try {
    if(value.length === 0) return false
    const discoveryStringBuffer = Buffer.from(value, 'base64')
    return JSON.parse(discoveryStringBuffer.toString('ascii'))
  } catch {return false}
}


export { buildDiscoveryObject }
