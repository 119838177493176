<script setup>
import { humanReadableTimestamp, humanReadableDuration, parseDuration } from '@/composables/datetime'
import { useI18n } from 'vue-i18n';
import { computed, ref } from 'vue';
import { useCameraName } from '@/composables/cameraHelpers';
import ArtifactPayload from '@/components/artifacts/ArtifactPayload.vue';
import { useArtifactStore } from '@/store/artifacts';
import WYSIWYG from '@/components/WYSIWYG.vue';
import { humanReadableFilesize } from '@/composables/filesizeHelpers.js';

const artifactStore = useArtifactStore()
const { t } = useI18n({})

const rawInformationDialog = ref(false)
const fullscreenPayload = ref(false)
const props = defineProps({ artifactId: { type: String, default: '' } })

const artifact = computed(() => {
  if (!artifactStore.artifacts[props.artifactId]) return null
  return artifactStore.artifacts[props.artifactId].data
})
const controlRecord = computed(() => {
  if (!artifactStore.artifacts[props.artifactId]) return null
  return artifactStore.artifacts[props.artifactId].controlRecord
})

const artifactTitle = computed(() => {
  return `${t('artifact.type.' + artifact.value.type)}, ${humanReadableTimestamp(artifact.value.timestamp)}`
})

const hasDuration = computed(() => {
  if (!artifact.value) return false
  if (!artifact.value.data) return false
  if (artifact.value.data.time) return true;
  return !!artifact.value.data.durationSeconds;

})

const humanReadableTime = computed(() => {
  if (!hasDuration.value) return null
  let start
  let end
  let duration
  if (artifact.value.data.time) {
    start = artifact.value.data.time.start
    end = artifact.value.data.time.end
    duration = parseDuration(artifact.value.data.time.duration)

    if (end === undefined) end = artifact.value.timestamp
    if (start === undefined) start = end - duration
    if (duration === undefined) duration = end - start

  } else {
    duration = artifact.value.data.durationSeconds * 1000
    end = artifact.value.timestamp
    start = end - duration
  }
  return `${humanReadableTimestamp(start)} - ${humanReadableTimestamp(end)} (${humanReadableDuration(duration)})`

})


const isDownloaded = computed(() => {
  if (!controlRecord.value) return false
  return controlRecord.value.downloaded
})

const isDownloading = computed(() => {
  if (!controlRecord.value) return false
  return controlRecord.value.downloading
})

async function downloadPayload() {
  await artifactStore.downloadArtifactPayload(props.artifactId)
}

async function savePayload() {
  if (!isDownloaded.value) return;
  artifactStore.savePayload(props.artifactId)
}

async function downloadAndSavePayload() {
  await downloadPayload();
  await savePayload()
}

</script>

<template>
  <v-layout-card
    v-if="artifact"
    elevation="8"
  >
    <v-card-title class="text-wrap">
      {{ artifactTitle }}
    </v-card-title>
    <v-card-text>
      <v-row
        :no-gutters="true"
      >
        <v-col cols="auto">
          <v-list density="compact">
            <v-list-item
              :title="t('artifact.properties.status')"
              :subtitle="t('artifact.status.'+artifact.status)"
              prepend-icon="mdi-information"
            />
            <v-list-item
              v-if="artifact.data && artifact.data.cameraId"
              :title="t('artifact.properties.cameraId')"
              :subtitle="useCameraName(artifact.data.cameraId)"
              prepend-icon="mdi-video"
            />
            <v-list-item
              v-if="artifact.jobName"
              :title="t('artifact.properties.jobName')"
              :subtitle="artifact.jobName"
              prepend-icon="mdi-note-check"
            />
            <v-list-item
              v-if="hasDuration"
              :title="t('artifact.properties.time')"
              :subtitle="humanReadableTime"
              prepend-icon="mdi-timelapse"
            />
            <v-list-item
              v-if="artifact && artifact.data && artifact.data.frameIntervalSeconds"
              :title="t('artifact.properties.frameInterval')"
              :subtitle="humanReadableDuration(artifact.data.frameIntervalSeconds * 1000)"
              prepend-icon="mdi-timer"
            />
            <v-list-item
              v-if="artifact && artifact.size"
              :title="t('artifact.properties.filesize')"
              :subtitle="humanReadableFilesize(artifact.size)"
              prepend-icon="mdi-harddisk"
            />
          </v-list>
        </v-col>
        <v-col class="justify-center">
          <v-card
            variant="flat"
            elevation="0"
          >
            <v-card-text>
              <ArtifactPayload :artifact-id="artifact.artifactId" />
            </v-card-text>
            <v-card-actions class="justify-end">
              <v-btn
                v-if="isDownloaded"
                prepend-icon="mdi-fullscreen"
              >
                {{ $t("general_interface.buttons.fullscreen") }}
                <v-dialog
                  v-model="fullscreenPayload"
                  :fullscreen="true"
                  activator="parent"
                >
                  <v-card>
                    <v-card-title>
                      {{ artifactTitle }}
                    </v-card-title>
                    <v-card-text>
                      <v-container class="fill-height">
                        <ArtifactPayload
                          v-if="fullscreenPayload"
                          :artifact-id="artifact.artifactId"
                          :full-screen="true"
                        />
                      </v-container>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        v-if="isDownloaded"
                        @click="savePayload"
                      >
                        {{ $t("general_interface.buttons.save") }}
                      </v-btn>
                      <v-btn @click="fullscreenPayload = false">
                        {{ $t("general_interface.buttons.close") }}
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <v-btn
        class="float-end"
        variant="flat"
      >
        <v-icon icon="mdi-information" />
        <v-dialog
          v-model="rawInformationDialog"
          activator="parent"
        >
          <v-card>
            <v-card-title>
              {{ artifactTitle }}
            </v-card-title>
            <v-card-text>
              <WYSIWYG
                :read-only="true"
                :model-value="`<pre><code>${JSON.stringify(artifact, null, 2)}</pre></code>`"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-btn>
    </v-card-text>
    <v-card-actions>
      <v-btn
        v-if="!isDownloaded && (artifact?.status === 'done' || artifact?.status === 'archived') "
        :disabled="isDownloading"
        @click="downloadPayload"
      >
        {{ $t("general_interface.buttons.download") }}
      </v-btn>
      <v-btn
        v-if="isDownloaded"
        @click="savePayload"
      >
        {{ $t("general_interface.buttons.save") }}
      </v-btn>
      <v-btn
        v-if="!isDownloaded && (artifact?.status === 'done' || artifact?.status === 'archived')"
        :disabled="isDownloading"
        @click="downloadAndSavePayload"
      >
        {{ $t("general_interface.buttons.download_and_save") }}
      </v-btn>
    </v-card-actions>
  </v-layout-card>
</template>
