import { defineStore } from 'pinia'
import backendHandler from '@/BackendAPI';

export const useVMSInstanceStore = defineStore('vmsInstances', {
  state: () => ({
    vmsInstances: null,
  }),
  persist: false,
  share: {
    enable: false,
    initialize: false,
  },
  getters: {

    getAllVMSInstances :state => state.vmsInstances,


  },
  actions: {

    async queryVMSInstances(payload) {
      const vmsInstanceResponse = await backendHandler.post(undefined, { path: '/api/vmsInstances/query', data: payload })
      if (!vmsInstanceResponse.ok) return null;
      this.vmsInstances = vmsInstanceResponse.data
      return vmsInstanceResponse.data
    },

    async loadInstanceForGlobalUniqueIdentifier(targetGuid) {
      const instances = await this.queryVMSInstances({
        query: [{
          key: 'globalUniqueIdentifier',
          operator: '==',
          value: targetGuid,
        }], limit: 1,
      })
      if (!instances || instances.length === 0) return null
      return instances[0]
    },
  },
})
