<script setup>
import { computed } from 'vue';
import { humanReadableFilesize } from '@/composables/filesizeHelpers';

const props = defineProps({
  artifactId: { type: String, default: '' },
  payload: { type: String, default: null },
  isDownloaded: { type: Boolean, default: false },
  artifactData: { type: Object, default: () => ({}) },
  fullScreen: { type: Boolean, default: false },
  isDownloading: { type: Boolean, default: false },
})

const filesize = computed(() => {
  return humanReadableFilesize(props.artifactData.size)
})

</script>

<template>
  <v-container class="fill-height justify-center align-center">
    <v-row
      :no-gutters="true"
      class="justify-center"
    >
      <v-col cols="auto">
        <v-icon
          icon="mdi-file-multiple"
          size="10vh"
        />
      </v-col>
      <v-col cols="auto">
        <v-list-item
          :title="$t('artifact.properties.filesize')"
          :subtitle="filesize"
          prepend-icon="mdi-harddisk"
        />
        <v-list-item
          :title="$t('artifact.properties.filename')"
          :subtitle="artifactData.preferredFilename"
          prepend-icon="mdi-form-textbox"
        />
        <v-list-item
          :title="$t('artifact.properties.checksum')"
          :subtitle="artifactData.md5"
          prepend-icon="mdi-barcode"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>

</style>
