import humanizeDuration from 'humanize-duration'
import parse from 'parse-duration'
import i18n from '../plugins/i18n'
import momentTimezone from 'moment-timezone'
import { DateTime } from 'luxon';

function humanReadableTimestamp(timestamp) {
  return new Date(timestamp).toLocaleString(i18n.global.locale.value,
    {
      second: '2-digit',
      minute: '2-digit',
      hour: '2-digit',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    },
  );
}

/**
 * Calculates the duration between the start timestamp and end timestamp and returns a human-readable string representation of the duration.
 *
 * @param {number} start - The start timestamp.
 * @param {number} [end] - The end timestamp. If not provided, the duration will be calculated from the start timestamp to the current time.
 * @param {Array<string>} [units] - The units to include in the duration string. Defaults to ['y', 'mo', 'd', 'h', 'm', 's'].
 * @param {boolean} [round] - Whether to round the duration values. Defaults to false.
 * @param {number} [maxDecimalPoints] - The maximum number of decimal points to include in the duration values.
 * @returns {string} - The human-readable string representation of the duration.
 */
function humanReadableDuration(start, end=undefined, units=undefined, round=false, maxDecimalPoints=undefined) {
  let duration = 0
  if(end === undefined) duration = start; else duration = end - start;
  if(!units) units = ['y', 'mo', 'd', 'h', 'm', 's']
  return humanizeDuration(duration, { units, round, language: i18n.global.locale.value, maxDecimalPoints } )
}

function humanReadableDurationInDays(start, end=undefined) {
  let duration = 0
  if(end === undefined) duration = start; else duration = end - start;
  return humanizeDuration(duration, { units: ['d'], language: i18n.global.locale.value, round: true } )
}

function humanReadableDurationInHoursAndMinutes(start, end=undefined) {
  let duration = 0
  if(end === undefined) duration = start; else duration = end - start;
  return humanizeDuration(duration, { units: ['h', 'm'], language: i18n.global.locale.value, round: true } )
}

function parseDuration(duration) {
  return parse(duration)
}

function getAvailableTimezones() {
  return momentTimezone.tz.names()
}

function getCurrentTimezone() {
  return momentTimezone.tz.guess()
}

/**
 * Calculates the number of days between two given timestamps.
 * If the end date is not provided, the current date is used.
 * This calculation is based on the Luxon library, 
 * which is safer when dealing with edge cases like daylight saving time.
 *
 * @param {number} start - The timestamp of the start date.
 * @param {number} end - The timestamp of the end date.
 * @returns {number} The number of days between the start and end dates (rounded down to the nearest whole number).
 */
function getDurationInDaysFromTimestamp(start, end = Date.now()) {
  const startDate = new Date(start).setHours(0, 0, 0, 0);
  return Math.floor(
    DateTime.fromMillis(end)
      .diff(DateTime.fromMillis(startDate.valueOf()), 'days')
      .toObject().days,
  );
};


export { humanReadableDurationInHoursAndMinutes, humanReadableTimestamp, humanReadableDuration, parseDuration, getAvailableTimezones, getCurrentTimezone, humanReadableDurationInDays, getDurationInDaysFromTimestamp }
