<script setup>
import i18n from '../../plugins/i18n'
const { t } = i18n.global
const { mobile } = useDisplay()
import { computed } from 'vue';
import { humanReadableDuration, humanReadableTimestamp } from '@/composables/datetime';
import { useDisplay } from 'vuetify';

const props = defineProps({ incident: { type: Object, default: () => ({}) } })

const humanReadableTime = computed(() => {
  return `${humanReadableTimestamp(props.incident.firstEventTimestamp)} - ${humanReadableTimestamp(props.incident.lastEventTimestamp)}`
})
const wasCreatedAutomatically = computed(() => {
  return (props.incident.triggeredBy.entityType !== 'account')
})
const age = computed(() => {
  if(props.incident.state === 'closed') return props.incident.lastEventTimestamp - props.incident.firstEventTimestamp;
  return Date.now() - props.incident.firstEventTimestamp
})

const ageColor = computed(() => {
  if(age.value >= 1000 * 60 * 60 * 24 * 2) return 'red';
  if(age.value >= 1000 * 60 * 60 * 24) return 'orange';
  return 'green';
})

const statusChipText = computed(() => {
  return t('incidents.deadAnimal.status.'+props.incident.state)
})
</script>

<template>
  <v-layout-card>
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-row class="ma-0 pa-0 align-center">
      <v-card-title>{{ $t("incidents.deadAnimal.title") }}</v-card-title>
      <v-chip
        v-if="!mobile"
        class="mx-2"
      >
        {{ statusChipText }}
      </v-chip>
    </v-row>
    <v-card-text>
      <v-list>
        <v-list-item
          v-if="mobile"
          prepend-icon="mdi-list-status"
          :title="$t('incidents.deadAnimal.titles.status')"
          :subtitle="statusChipText"
        />
        <v-list-item
          v-if="incident.messagePayload.falsePositive"
          prepend-icon="mdi-thumb-down"
          :title="$t('incidents.deadAnimal.titles.false_positive')"
          :subtitle="$t('incidents.deadAnimal.properties.false_positive')"
        />
        <v-list-item
          v-if="incident.state !== 'closed' && incident.isPublicOpen === false"
          prepend-icon="mdi-eye-off"
          :title="$t('incidents.titles.public_closed')"
          :subtitle="$t('incidents.properties.public_closed')"
        />
        <v-list-item
          v-if="incident.state !== 'closed'"
          prepend-icon="mdi-clock-start"
          :title="$t('incidents.deadAnimal.titles.firstDetection')"
          :subtitle="humanReadableTimestamp(incident.firstEventTimestamp)"
        />
        <v-list-item
          v-if="incident.state === 'closed'"
          prepend-icon="mdi-clock"
          :title="$t('incidents.deadAnimal.titles.time')"
          :subtitle="humanReadableTime"
        />

        <v-list-item
          :title="$t('incidents.deadAnimal.titles.age')"
          prepend-icon="mdi-timelapse"
        >
          <template #subtitle>
            <v-chip
              :color="ageColor"
              variant="flat"
            >
              {{ humanReadableDuration(age) }}
            </v-chip>
          </template>
        </v-list-item>

        <v-list-item
          :title="$t('incidents.deadAnimal.titles.camera')"
          :subtitle="incident.messagePayload.cameraName"
          prepend-icon="mdi-video"
        />
        <v-list-item
          v-if="wasCreatedAutomatically"
          :title="$t('incidents.deadAnimal.titles.created_automatically')"
          :subtitle="$t('incidents.deadAnimal.properties.created_automatically')"
          prepend-icon="mdi-robot"
        />
        <v-list-item
          v-if="!wasCreatedAutomatically"
          :title="$t('incidents.deadAnimal.titles.creator')"
          :subtitle="incident.triggeredBy.displayName"
          prepend-icon="mdi-account"
        />
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{
          enableNewEventButton: incident.state !== 'closed',
          newEventButtonText: $t('incidents.deadAnimal.actions.new_event')}"
      />
    </v-card-actions>
  </v-layout-card>
</template>

<style scoped>

</style>
