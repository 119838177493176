import { defineStore } from 'pinia'
import { useDownloadFile } from '@/composables/downloadFile';
import backendHandler from '@/BackendAPI';
import { useInstanceStore } from '@/store/backendInstances';
import { useLogsStore } from '@/store/logs.js';

export const useArtifactStore = defineStore('artifacts', {
  state: () => ({
    artifacts: {},
    artifactControlRecords: {},
    artifactDownloadPromises: {},
  }),
  persist: false,
  share: {
    omit: ['artifactControlRecords', 'artifactDownloadPromises'],
    enable: true,
    initialize: false,
  },
  getters: {},
  actions: {

    clearArtifacts() {
      this.artifacts = {}
      this.artifactControlRecords = {}
      this.artifactDownloadPromises = {}
    },
    async getLatestArtifactForCameraAndArtifactTypeWithTimestamp(cameraId, artifactType, timestamp, signal = undefined, higherPriority = false) {
      const body = {
        query: [
          { key: 'data.cameraId', operator: '==', value: cameraId },
          { key: 'type', operator: '==', value: artifactType },
          { key: 'timestamp', operator: '<=', value: timestamp },
          { key: 'status', operator: 'in', value: ['done', 'archived'] },
        ],
        order: { timestamp: 'desc' },
      }
      const artifactResponse = await backendHandler.post(useInstanceStore().selectedInstanceId, {
        path: '/api/artifact/data/queryOne',
        data: body,
        signal,
        priority: higherPriority ? 5 : 6,
      })
      if (!artifactResponse.ok) {
        if (artifactResponse.aborted) {
          return null;
        }
        throw artifactResponse.error

      }
      const artifact = artifactResponse.data;
      if (!this.artifacts[artifact.artifactId]) this.artifacts[artifact.artifactId] = {}
      this.artifacts[artifact.artifactId].data = artifact
      this.artifacts[artifact.artifactId].multiResolutionSource = ['heatmap', 'longTimeExposure', 'longTimeExposureAnnotation', 'singleFrame'].includes(artifact.type);
      return artifact.artifactId
    },

    async loadArtifactData(artifactId) {
      const artifactResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, { path: '/api/artifact/data/' + artifactId })
      if (!artifactResponse.ok) return null;
      const artifact = artifactResponse.data;
      if (!this.artifacts[artifact.artifactId]) this.artifacts[artifact.artifactId] = {}
      this.artifacts[artifact.artifactId].data = artifact
      this.artifacts[artifact.artifactId].multiResolutionSource = ['heatmap', 'longTimeExposure', 'longTimeExposureAnnotation', 'singleFrame'].includes(artifact.type);
      return artifact
    },

    async loadArtifact(artifactId, height = 1080, signal, higherPriority = false) {
      const heights = [1080, 800, 540, 270, 150, 50, 20]

      if (this.artifacts[artifactId] && this.artifacts[artifactId].payload && this.artifacts[artifactId].payload[height]) return

      const artifactResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {
        path: '/api/artifact/' + artifactId,
        params: { resizeY: height },
        responseType: 'blob',
        signal,
        priority: higherPriority ? 4 : undefined,
      })
      if (artifactResponse.ok) {
        if (!this.artifacts[artifactId]) {
          this.artifacts[artifactId] = {}
        }
        if (!this.artifacts[artifactId].payload) this.artifacts[artifactId].payload = {}

        const objectUrl = URL.createObjectURL(artifactResponse.data)

        const img = new Image()
        img.src = objectUrl
        await img.decode()
        const realHeight = img.naturalHeight
        if (realHeight !== height) {
          heights.forEach(h => {
            if (h <= realHeight)
              this.artifacts[artifactId].payload[h] = objectUrl
          })
        }

        this.artifacts[artifactId].payload[height] = objectUrl
      } else {
        if (artifactResponse.aborted) {
          throw new Error('Aborted')
        }
      }
    },

    async queryArtifacts(payload, globalUniqueIdentifier = undefined) {
      const globalUniqueIdentifierToUse = globalUniqueIdentifier ? globalUniqueIdentifier : useInstanceStore().selectedInstanceId
      const artifactResponse = await backendHandler.post(globalUniqueIdentifierToUse, {
        path: '/api/artifact/data/query',
        data: payload,
      })
      if (!artifactResponse.ok) return null;
      if(payload.projection === 'count') return artifactResponse.data
      for (const artifact of artifactResponse.data) {
        if (!this.artifacts[artifact.artifactId]) this.artifacts[artifact.artifactId] = {}
        this.artifacts[artifact.artifactId].data = artifact
        this.artifacts[artifact.artifactId].multiResolutionSource = ['heatmap', 'longTimeExposure', 'longTimeExposureAnnotation', 'singleFrame'].includes(artifact.type);
      }
      return artifactResponse.data
    },

    async downloadArtifactPayload(artifactId) {
      if (
        this.artifacts[artifactId] &&
        this.artifacts[artifactId].controlRecord &&
        this.artifacts[artifactId].controlRecord.downloaded === true) return Promise.resolve(this.artifacts[artifactId].payload)

      if (this.artifactDownloadPromises[artifactId]) return this.artifactDownloadPromises[artifactId];

      this.artifactDownloadPromises[artifactId] = async () => {
        if(!this.artifacts[artifactId]) {this.artifacts[artifactId] = {}}
        if (!this.artifacts[artifactId].controlRecord) this.artifacts[artifactId].controlRecord = { downloading: true, downloaded: false }
        const artifactResponse = await backendHandler.get(useInstanceStore().selectedInstanceId, {
          path: '/api/artifact/' + artifactId,
          responseType: 'blob',
        })
        if (!artifactResponse.ok) return;
        if (!Object.keys(this.artifacts).includes(artifactId)) {
          this.artifacts[artifactId] = {}
        }
        if (!Object.keys(this.artifacts[artifactId]).includes('payload'))
          this.artifacts[artifactId].payload = {}

        if (this.artifacts[artifactId].multiResolutionSource) {
          try {
            const objectUrl = URL.createObjectURL(artifactResponse.data)
            const img = new Image()
            img.src = objectUrl
            await img.decode()
            const realHeight = img.naturalHeight
            this.artifacts[artifactId].payload[realHeight] = objectUrl
            if (!this.artifacts[artifactId].controlRecord) this.artifacts[artifactId].controlRecord = {}
            this.artifacts[artifactId].controlRecord.downloaded = true
            this.artifacts[artifactId].controlRecord.downloading = false
          } catch (err) {
            useLogsStore().addLogEntry({ message: 'Error while decoding image', error: err, tag: 'artifacts', level: 'ERROR' })
          }
        } else {
          this.artifacts[artifactId].payload = URL.createObjectURL(artifactResponse.data)
          if (!this.artifacts[artifactId].controlRecord) this.artifacts[artifactId].controlRecord = {}
          this.artifacts[artifactId].controlRecord.downloaded = true
          this.artifacts[artifactId].controlRecord.downloading = false
          return this.artifacts[artifactId].payload
        }
      }
      return this.artifactDownloadPromises[artifactId]()
    },

    savePayload(artifactId) {
      if (
        !this.artifacts[artifactId] ||
        !this.artifacts[artifactId].controlRecord ||
        this.artifacts[artifactId].controlRecord.downloaded !== true) {
        return false;
      }
      let blob
      if (this.artifacts[artifactId].multiResolutionSource) {
        blob = this.artifacts[artifactId].payload[Math.max(...Object.keys(this.artifacts[artifactId].payload).map(key => parseInt(key)))]
      } else {
        blob = this.artifacts[artifactId].payload;
      }

      if (!blob) return;
      useDownloadFile(blob, this.artifacts[artifactId].data.preferredFilename)
      return true
    },

    async downloadAndSaveArtifactPayload(artifactId) {
      try {
        await this.downloadArtifactPayload(artifactId)
        return this.savePayload(artifactId)
      } catch(e) {
        useLogsStore().addLogEntry({ message: 'Could not download artifact ' + artifactId, error: e, level: 'ERROR', tag: 'ARTIFACT' })
        return false
      }
      return true
    },
  },
})
