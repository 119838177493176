import { ref } from 'vue';
import { useToast } from 'vue-toastification';
const toast = useToast();
import i18n from '../plugins/i18n'
import Konva from 'konva';
const { t } = i18n.global

const labelingMode = ref('box')
const cameraBound = ref(false)
const cameraId = ref(null)
const pointerDown = ref(false)
const relativeStart = ref(null)
const relativeEnd = ref(null)
const absoluteStart = ref(null)
const absoluteEnd = ref(null)
const absolutePosition = ref(null)
const targetProvider = ref(null)
const imageLayer = ref(null)
const labelingLayer = ref(null)
const currentShape = ref(null)
const stage = ref(null)
const providers = ref({})
const points = ref([])
const deadAnimalIncidentTriggerPayload = ref({})
const showDeadAnimalIncidentTrigger = ref(false)
const lastCameraBoundWarningToast = ref(0)
const getTimestamp = ref(null)
async function triggerDeadAnimal({ x,y,w,h, cameraId, artifactId }) {
  const centerX = (x + (w / 2))
  const centerY = (y + (h / 2))
  const payload = {
    cameraId: cameraId,
    detection:{
      x: centerX,
      y: centerY,
      w: w,
      h: h,
      timestamp: getTimestamp.value().toMillis(),
      artifactId: artifactId,
    },
  }

  showDeadAnimalIncidentTrigger.value = true
  deadAnimalIncidentTriggerPayload.value = payload
}

function maybeShowCameraBoundWarningToast() {
  if(lastCameraBoundWarningToast.value < Date.now() - 3000) {
    lastCameraBoundWarningToast.value = Date.now()
    toast.warning(t('canvas.cameraBoundWarning'))
  }
}

const closingTrigger = ref(null)

function setupLabelingHelper(setup) {
  getTimestamp.value = setup.getTimestamp
  providers.value = setup.providers
  imageLayer.value = setup.imageLayer
  labelingLayer.value = setup.labelingLayer
  stage.value = setup.stage

  switch(setup.labelingType) {
  case 'deadAnimal': {
    labelingMode.value = 'box'
    cameraBound.value = true
    closingTrigger.value = triggerDeadAnimal;
    break;
  }
  case 'region': {
    labelingMode.value = 'polygon'
    cameraBound.value = false
    //closingTrigger.value = saveRegion;
    break
  }
  }

  imageLayer.value.on('pointerdown', pointerDownListener)
  imageLayer.value.on('pointerup', pointerUpListener)
  imageLayer.value.on('pointermove', pointerMoveListener)
  imageLayer.value.on('click', clickListener)

  labelingLayer.value.on('pointerdown', pointerDownListener)
  labelingLayer.value.on('pointerup', pointerUpListener)
  labelingLayer.value.on('pointermove', pointerMoveListener)
  labelingLayer.value.on('click', clickListener)

}

function tearDownLabelingHelper() {
  points.value = []

  imageLayer.value.off('pointerdown', pointerDownListener)
  imageLayer.value.off('pointerup', pointerUpListener)
  imageLayer.value.off('pointermove', pointerMoveListener)

  labelingLayer.value.off('pointerdown', pointerDownListener)
  labelingLayer.value.off('pointerup', pointerUpListener)
  labelingLayer.value.off('pointermove', pointerMoveListener)
}

function createNewBox() {
  currentShape.value = new Konva.Rect({
    x: absoluteStart.value.x,
    y: absoluteStart.value.y,
    width: (absolutePosition.value.x - absoluteStart.value.x),
    height: (absolutePosition.value.y - absoluteStart.value.y),
    fill: 'rgba(0,0,0,0)',
    stroke: 'red',
    strokeWidth: 4 / stage.value.getStage().scaleX(),
    attrs: { cameraId: cameraId.value },
  })

  labelingLayer.value.add(currentShape.value)
  labelingLayer.value.draw()
}

function drawPolygon() {
  if(currentShape.value) currentShape.value.destroy()
  currentShape.value = new Konva.Line()

  if(points.value && points.value.length === 1) {
    currentShape.value = new Konva.Circle({
      radius: 2,
      x: points.value[0].globalX,
      y: points.value[0].globalY,
      stroke: 'red',
    })
  } else {
    currentShape.value = new Konva.Line({
      points: points.value.flatMap(point => [point.globalX, point.globalY]),
      stroke: 'red',
      strokeWidth: 4 / stage.value.getStage().scaleX(),
      closed: false,
      fillEnabled: false,
    })
  }
  labelingLayer.value.add(currentShape.value)
}

function finishPolygon() {
  if(currentShape.value) currentShape.value.destroy()
  currentShape.value = new Konva.Line({
    points: points.value.flatMap(point => [point.globalX, point.globalY]),
    stroke: 'red',
    strokeWidth: 4 / stage.value.getStage().scaleX(),
    closed: true,
    fillEnabled: false,
  })
  labelingLayer.value.add(currentShape.value)
  labelingLayer.value.draw()
  points.value = []
  if(closingTrigger.value) closingTrigger.value({ points: currentShape.value.points(), cameraId: cameraId.value, target: targetProvider.value })
  cameraId.value = null
  targetProvider.value = null
}

function findDistanceToClosestExistingPoint(point) {
  const distances = points.value.map(existingPoint => {
    const dx = existingPoint.globalX - point.globalX
    const dy = existingPoint.globalY - point.globalY
    return Math.sqrt(dx * dx + dy * dy)
  })
  return Math.min(...distances)
}

function findDistanceToFirstPoint(point) {
  const dx = points.value[0].globalX - point.globalX
  const dy = points.value[0].globalY - point.globalY
  return Math.sqrt(dx * dx + dy * dy)
}


function addPoint(point) {

  if(points.value.length > 2 && findDistanceToFirstPoint(point) < 10) {
    finishPolygon()
    return
  }
  if(findDistanceToClosestExistingPoint(point) >= 5) {
    points.value.push(point)
    drawPolygon()
  }

}

function updateBox() {
  currentShape.value.width(absolutePosition.value.x - absoluteStart.value.x)
  currentShape.value.height(absolutePosition.value.y - absoluteStart.value.y)
  labelingLayer.value.draw()
}

function finishBox() {
  updateBox()
  const x = Math.min(relativeStart.value.x, relativeEnd.value.x)
  const y = Math.min(relativeStart.value.y, relativeEnd.value.y)
  const w = Math.abs(relativeEnd.value.x - relativeStart.value.x)
  const h = Math.abs(relativeEnd.value.y - relativeStart.value.y)

  if(closingTrigger.value)
    closingTrigger.value({ x, y, w, h, cameraId: cameraId.value, target: targetProvider.value })
  cameraId.value = null
  targetProvider.value = null
}

const pointerMoveListener = e => {
  if(labelingMode.value !== 'box') return
  if(!pointerDown.value) return

  if(cameraBound.value === true
    && e.target.attrs.cameraId
    && cameraId.value !== e.target.attrs.cameraId) {
    maybeShowCameraBoundWarningToast()
    return
  }
  const x = e.target.getStage().getRelativePointerPosition(imageLayer.value).x
  const y = e.target.getStage().getRelativePointerPosition(imageLayer.value).y
  absolutePosition.value = { x,y }

  updateBox()
  e.evt.preventDefault()
}

const pointerDownListener = e => {
  if(labelingMode.value !== 'box') return

  if(cameraBound.value === true) {
    cameraId.value = e.target.attrs.cameraId
  }

  const element =  providers.value[e.target.attrs.cameraId].getKonvaElement()
  pointerDown.value = true
  const x = stage.value.getStage().getRelativePointerPosition().x
  const y = stage.value.getStage().getRelativePointerPosition().y

  const r = element.getRelativePointerPosition()
  const rx = r.x / (element.width())
  const ry = r.y / (element.height())
  relativeStart.value = { x: rx, y: ry }
  relativeEnd.value = { x: null, y: null }
  targetProvider.value = element
  absoluteStart.value = { x,y }
  absolutePosition.value = { x,y }
  absoluteEnd.value = { x: null, y: null }
  createNewBox()
  e.evt.preventDefault()
}

const pointerUpListener = e => {
  if(labelingMode.value !== 'box') return

  if(cameraBound.value === true
    && e.target.attrs.cameraId
    && cameraId.value !== e.target.attrs.cameraId) {
    maybeShowCameraBoundWarningToast()
    return
  }

  pointerDown.value = false
  const x = e.target.getStage().getRelativePointerPosition(imageLayer.value).x
  const y = e.target.getStage().getRelativePointerPosition(imageLayer.value).y

  const element = targetProvider.value
  const r = element.getRelativePointerPosition()
  const rx = r.x / (element.width())
  const ry = r.y / (element.height())
  relativeEnd.value = { x: rx, y: ry }

  absolutePosition.value = { x,y }
  absoluteEnd.value = { x,y }

  finishBox()
  e.evt.preventDefault()
}

const clickListener = e => {
  if(labelingMode.value !== 'polygon') return

  const element =  providers.value[e.target.attrs.cameraId].getKonvaElement()
  if(cameraBound.value === true) {
    cameraId.value = e.target.attrs.cameraId
    targetProvider.value = element
  }
  const x = stage.value.getStage().getRelativePointerPosition().x
  const y = stage.value.getStage().getRelativePointerPosition().y

  const r = element.getRelativePointerPosition()
  const rx = r.x / (element.width())
  const ry = r.y / (element.height())

  addPoint({ element: element, globalX: x, globalY: y, elementX: rx, elementY: ry })
  e.evt.preventDefault()
}

export { setupLabelingHelper, tearDownLabelingHelper, showDeadAnimalIncidentTrigger, deadAnimalIncidentTriggerPayload }
