<script setup>

import { onMounted, ref, watch } from 'vue';
import { splitIncidents, splittingModes } from '@/composables/incidents/incidentPlottingHelpers';
import i18n from '@/plugins/i18n';
import BaseChart from '@/components/charts/BaseChart.vue';
const { t } = i18n.global
const props = defineProps({
  mode: { type: String, default: splittingModes.opened },
  start: { type: Number, default: 0 },
  end: { type: Number, default: 0 },
  timeResolution: { type: Number, default: 6 },
  incidents: { type: Array, default: () => [] },
  dimensionKey: { type: String, default: 'type' },
  timeShift: { type: Number, default: 0 },
  dimensionNamingDictionary: { type: Object, default: () => ({}) },
})
const chart = ref(null)
const timeSetup = ref({ start: props.start, end: props.end, timeResolution: props.timeResolution })
const options = ref({})
watch(() => props.incidents, () => {
  return chart.value?.loadData()
})

watch(() => props.timeResolution, () => {
  return chart.value?.loadData()
})

watch(() => props.timeShift, () => {
  return chart.value?.loadData()
})




function loadData() {
  const splitResult = splitIncidents(props.incidents, props.timeResolution, props.start, props.end, props.mode, props.dimensionKey, props.timeShift, props.dimensionNamingDictionary)
  for (let i = 0; i < splitResult.segmentBounds.length; i++) {
    for (let j = 0; j < splitResult.series.length; j++) {
      splitResult.series[j].data[i] = {
        y: splitResult.series[j].data[i],
        x: splitResult.segmentBounds[i].start,
      }
    }
  }
  return { series: splitResult.series }
}

onMounted(async () => {
})
</script>

<template>
  <BaseChart
    :ref="chart"
    v-model:time-setup="timeSetup"
    :height="400"
    :disable-control-row="true"
    :chart-type="mode === splittingModes.open ? 'line' : 'column'"
    :options="options"
    :data-loader="loadData"
    x-axis-type="datetime"
    :title="t('charts.titles.deadAnimalIncidents')"
  />
</template>

<style scoped>

</style>
