import { useCamerasStore } from '@/store/cameras';
import i18n from '../plugins/i18n'
const { t } = i18n.global

function useCameraName(cameraId) {
  const camera = useCamerasStore().getCameraById(undefined, cameraId)
  if(!camera) return t('cameras.unknown')
  return camera.name
}

export { useCameraName }
