import i18n from '../../plugins/i18n'
import { useInstanceStore } from '@/store/backendInstances';
import { EQ, GT, GTE, IN, LT, LTE, NEQ, NIN } from '@/composables/filters/operators';
import {
  getAvailableProperties,
  getDataType,
  getOperators,
  getOptions, getOptionTitle,
  getPropertyTitle, getSortableProperties,
} from '@/composables/filters/filterHelpers';
const { t } = i18n.global



const properties = {
  state: {
    title: t('filters.incidents.properties.state.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'autocomplete',
    options: [
      {
        id: 'open',
        title: t('filters.incidents.properties.state.options.open.title'),
        enabled: () => true,
      },
      {
        id: 'closed',
        title: t('filters.incidents.properties.state.options.closed.title'),
        enabled: () => true,
      },
      {
        id: 'created',
        title: t('filters.incidents.properties.state.options.created.title'),
        enabled: () => true,
      },
      {
        id: 'deadAnimalExists',
        title: t('filters.incidents.properties.state.options.deadAnimalExists.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.deadAnimal.view']),
      },
      {
        id: 'hadNoDarknessPeriod',
        title: t('filters.incidents.properties.state.options.hadNoDarknessPeriod.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.light.view']),
      },
      {
        id: 'actionRequired',
        title: t('filters.incidents.properties.state.options.actionRequired.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.barnIntervention.view']),
      },
      {
        id: 'issueResolved',
        title: t('filters.incidents.properties.state.options.issueResolved.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.barnIntervention.view']),
      },
    ],
  },
  type: {
    title: t('filters.incidents.properties.type.title'),
    operators: [EQ, NEQ, IN, NIN ],
    dataType: 'autocomplete',
    options: [
      {
        id: 'expressDebugMode',
        title: t('filters.incidents.properties.type.options.expressDebugMode.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.expressDebugMode.view']),
      },
      {
        id: 'expressMaintenanceMode',
        title: t('filters.incidents.properties.type.options.expressMaintenanceMode.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.expressMaintenanceMode.view']),
      },
      {
        id: 'certificate',
        title: t('filters.incidents.properties.type.options.certificate.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.certificate.view']),
      },
      {
        id: 'healthCheck',
        title: t('filters.incidents.properties.type.options.healthCheck.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.healthCheck.view']),
      },
      {
        id: 'freeText',
        title: t('filters.incidents.properties.type.options.freeText.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.freeText.view']),
      },
      {
        id: 'timeSeries',
        title: t('filters.incidents.properties.type.options.timeSeries.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.timeSeries.view']),
      },
      {
        id: 'singleValueInfluxQuery',
        title: t('filters.incidents.properties.type.options.singleValueInfluxQuery.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.singleValueInfluxQuery.view']),
      },
      {
        id: 'speed',
        title: t('filters.incidents.properties.type.options.speed.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.speed.view']),
      },
      {
        id: 'deadAnimal',
        title: t('filters.incidents.properties.type.options.deadAnimal.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.deadAnimal.view']),
      },
      {
        id: 'cameraPrivacy',
        title: t('filters.incidents.properties.type.options.cameraPrivacy.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.cameraPrivacy.view']),
      },
      {
        id: 'barnIntervention',
        title: t('filters.incidents.properties.type.options.barnIntervention.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.barnIntervention.view']),
      },
      {
        id: 'barnDocumentation',
        title: t('filters.incidents.properties.type.options.barnDocumentation.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.barnDocumentation.view']),
      },
      {
        id: 'penState',
        title: t('filters.incidents.properties.type.options.penState.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.penState.view']),
      },
      {
        id: 'light',
        title: t('filters.incidents.properties.type.options.light.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.light.view']),
      },
      {
        id: 'animalDistribution',
        title: t('filters.incidents.properties.type.options.animalDistribution.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.animalDistribution.view']),
      },
      {
        id: 'pigsLying',
        title: t('filters.incidents.properties.type.options.pigsLying.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.pigsLying.view']),
      },
      {
        id: 'driveAvailability',
        title: t('filters.incidents.properties.type.options.driveAvailability.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.driveAvailability.view']),
      },
      {
        id: 'driveUtilization',
        title: t('filters.incidents.properties.type.options.driveUtilization.title'),
        enabled: () => useInstanceStore().hasAnyPermission(['incidents.view', 'incidents.driveUtilization.view']),
      },
    ],
  },
  firstEventTimestamp: {
    title: t('filters.incidents.properties.firstEventTimestamp.title'),
    operators: [GTE, LTE, EQ, GT, LT],
    dataType: 'timestamp',
  },
  lastEventTimestamp: {
    title: t('filters.incidents.properties.lastEventTimestamp.title'),
    operators: [GTE, LTE, EQ, GT, LT],
    dataType: 'timestamp',
  },
}

const sortableProperties = [
  'firstEventTimestamp',
  'lastEventTimestamp',
  'type',
]

export default {
  getOptionTitle: (propertyId, optionId) => getOptionTitle(properties, propertyId, optionId),
  getPropertyTitle: propertyId => getPropertyTitle(properties, propertyId),
  getAvailableProperties: () => getAvailableProperties(properties),
  getOperators: propertyId => getOperators(properties, propertyId),
  getDataType: propertyId => getDataType(properties, propertyId),
  getOptions: propertyId => getOptions(properties, propertyId),
  getSortableProperties: () => getSortableProperties(sortableProperties, properties),
}
