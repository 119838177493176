<script setup>
import { computed, onMounted, ref } from 'vue';
import { useIncidentStore } from '@/store/incident';
import { useCameraName } from '@/composables/cameraHelpers';
import DateTimePicker from '@/components/DateTimePicker.vue';
import FrameSeek from '@/views/FrameSeek.vue';
import { DateTime } from 'luxon';

const incidentStore = useIncidentStore()
const props = defineProps({
  showCancelButton: { type: Boolean, default: false },
  newIncidentPayload: { type: Object, default: null },
  incident: { type: Object, default: null },
})
const emit = defineEmits(['cancel', 'saved'])
const showFrameSeek = ref(false)
const detectedTimestamp = ref(null)
const isAlreadyRemoved = ref(false)
const removedTimestamp = ref(null)
const cameraName = computed(() => {
  if (!props.newIncidentPayload) return ''
  return useCameraName(props.newIncidentPayload.cameraId)
})

const timestampWasProvided = computed(() => {
  return !!props.newIncidentPayload?.detection?.timestamp
})

function cancel() {
  emit('cancel')
}

async function triggerDeadAnimal() {
  const triggerPayload = {
    isCurrentDetection: !isAlreadyRemoved.value,
    cameraId: props.newIncidentPayload.cameraId,
    removal: isAlreadyRemoved.value ? {
      timestamp: removedTimestamp.value.toMillis(),
    } : undefined,
    detection: {
      timestamp: detectedTimestamp.value.toMillis(),
      x: props.newIncidentPayload.detection.x,
      y: props.newIncidentPayload.detection.y,
      w: props.newIncidentPayload.detection.w,
      h: props.newIncidentPayload.detection.h,
      artifactId: props.newIncidentPayload.detection.artifactId,
    },
  }

  await incidentStore.triggerDeadAnimal(triggerPayload)
  emit('saved')
}

async function markAsRemoved() {
  await incidentStore.markDeadAnimalAsRemoved(props.incident.incidentId, removedTimestamp.value.toMillis())
  emit('saved')
}

async function markAsFalsePositive() {
  await incidentStore.markDeadAnimalAsFalsePositive(props.incident.incidentId, props.incident.firstEventTimestamp)
  emit('saved')
}

async function revertFalsePositive() {
  await incidentStore.markDeadAnimalAsNotFalsePositive(props.incident.incidentId)
  emit('saved')
}

onMounted(() => {
  if (timestampWasProvided.value)
    detectedTimestamp.value = DateTime.fromMillis(props.newIncidentPayload.detection.timestamp)
})
</script>

<template>
  <v-layout-card v-if="!incident">
    <v-card-title>{{ $t('incidents.deadAnimal.trigger_title', {cameraName}) }}</v-card-title>
    <v-card-text>
      <DateTimePicker
        v-model="detectedTimestamp"
        :label="$t('incidents.deadAnimal.detected_timestamp')"
        :disabled="timestampWasProvided"
      />

      <v-checkbox
        v-model="isAlreadyRemoved"
        :label="$t('incidents.deadAnimal.is_removed')"
      />

      <DateTimePicker
        v-if="isAlreadyRemoved"
        v-model="removedTimestamp"
        :label="$t('incidents.deadAnimal.removed_timestamp')"
      />
      <v-btn
        v-if="isAlreadyRemoved"
        size="small"
        variant="outlined"
        :block="true"
        class="ma-2"
      >
        {{ $t("incidents.deadAnimal.frame_seek_button") }}
        <v-dialog
          v-model="showFrameSeek"
          activator="parent"
          :fullscreen="true"
        >
          <FrameSeek
            :pre-picked-camera="props.newIncidentPayload.cameraId"
            :camera-read-only="true"
            :timestamp-picker-mode="true"
            :use-query-string="false"
            @pick-timestamp="removedTimestamp = $event; showFrameSeek = false"
          />
        </v-dialog>
      </v-btn>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        v-if="showCancelButton"
        variant="outlined"
        color="error"
        @click="cancel"
      >
        {{ $t("general_interface.buttons.cancel") }}
      </v-btn>
      <v-btn
        variant="elevated"
        color="primary"
        @click="triggerDeadAnimal"
      >
        {{ $t("general_interface.buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-layout-card>
  <v-layout-card v-if="incident">
    <v-card-title>{{ $t("incidents.deadAnimal.update.title") }}</v-card-title>
    <v-card-text>
      <v-card
        color="blue"
        class="my-2"
      >
        <v-card-title>{{ $t("incidents.deadAnimal.update.removed.title") }}</v-card-title>
        <v-card-text>
          <v-alert type="info">
            {{ $t("incidents.deadAnimal.update.removed.text") }}
          </v-alert>
          <DateTimePicker v-model="removedTimestamp" />
        </v-card-text>
        <v-card-actions>
          <v-btn
            :block="true"
            @click="markAsRemoved"
          >
            {{ $t("incidents.deadAnimal.update.removed.button") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="incident?.messagePayload?.falsePositive !== true"
        color="red"
        class="my-2"
      >
        <v-card-title>
          {{ $t("incidents.deadAnimal.update.false_positive.title") }}
        </v-card-title>
        <v-card-text>
          <v-alert
            type="info"
            color="red"
          >
            {{ $t("incidents.deadAnimal.update.false_positive.text") }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :block="true"
            @click="markAsFalsePositive"
          >
            {{ $t("incidents.deadAnimal.update.false_positive.button") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-if="incident?.messagePayload?.falsePositive === true"
        color="red"
        class="my-2"
      >
        <v-card-title>
          {{ $t("incidents.deadAnimal.update.revert_false_positive.title") }}
        </v-card-title>
        <v-card-text>
          <v-alert
            type="info"
            color="red"
          >
            {{ $t("incidents.deadAnimal.update.revert_false_positive.text") }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :block="true"
            @click="revertFalsePositive"
          >
            {{ $t("incidents.deadAnimal.update.revert_false_positive.button") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-layout-card>
</template>

<style scoped>

</style>
