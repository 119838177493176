import { DateTime } from 'luxon';
import i18n from '@/plugins/i18n';

const { t } = i18n.global

const splittingModes = {
  opened: 'OPENED',
  open: 'OPEN',
  closed: 'CLOSED',
}

function splitIncidents(incidents, segmentLength, startTimestamp, endTimestamp, mode = splittingModes.opened, dimensionKey = undefined, timeShift = 0, dimensionNamingDictionary = undefined) {

  const now = DateTime.now()
  //initialization
  const startDateTime = DateTime.fromMillis(startTimestamp).startOf('day')
  let endDateTime = DateTime.fromMillis(endTimestamp).endOf('day').plus({ hours: timeShift })

  if (endDateTime.offset !== DateTime.fromMillis(endTimestamp).offset) {
    const diff = endDateTime.offset - DateTime.fromMillis(endTimestamp).offset
    endDateTime = endDateTime.plus({ minutes: diff })
  }

  const dimensions = []

  //build all possible dimensions from incidents
  if (dimensionKey) {
    dimensions.push(...incidents.map(incident => dimensionKey.split('.').reduce((a, b) => a[b], incident)))
  } else dimensions.push('_total')
  const uniqueDimensions = [...new Set(dimensions)]

  const segmentBounds = []
  let currentStartTimestamp = startDateTime.plus({ hours: timeShift })

  //if there is a DST change between the start Date and the start Date + timeShift, adjust it to reflect the Change
  if (startDateTime.offset !== currentStartTimestamp.offset) {
    const diff = startDateTime.offset - currentStartTimestamp.offset
    currentStartTimestamp = currentStartTimestamp.plus({ minutes: diff })
  }

  while (currentStartTimestamp < endDateTime) {
    let currentEndTimestamp = currentStartTimestamp.plus({ hours: segmentLength })

    let includesDSTShift = false
    if (currentStartTimestamp.offset !== currentEndTimestamp.offset) {
      const diff = currentStartTimestamp.offset - currentEndTimestamp.offset
      currentEndTimestamp = currentEndTimestamp.plus({ minutes: diff })
      includesDSTShift = true
    }
    let attributedTimestamp = currentStartTimestamp.minus({ hours: timeShift })
    if (currentStartTimestamp.offset !== attributedTimestamp.offset) {
      const diff = currentStartTimestamp.offset - attributedTimestamp.offset
      attributedTimestamp = attributedTimestamp.plus({ minutes: diff })
    }


    if (attributedTimestamp >= startDateTime) {
      const segmentBound = {
        start: currentStartTimestamp.toMillis(),
        end: currentEndTimestamp.toMillis(),
        attributedDay: `${attributedTimestamp.year.toString().padStart(4, '0')}-${attributedTimestamp.month.toString().padStart(2, '0')}-${attributedTimestamp.day.toString().padStart(2, '0')}`,
        includesDSTShift,
        actualLength: currentEndTimestamp.diff(currentStartTimestamp, 'hours').hours,
        alreadyDone: currentEndTimestamp < now,
        notYetStarted: currentStartTimestamp > now,
      }
      segmentBounds.push(segmentBound)
    }
    currentStartTimestamp = currentEndTimestamp
  }

  const seriesHolder = {}
  for (const dimension of uniqueDimensions) {
    seriesHolder[dimension] = []
    for (const segmentBound of segmentBounds) {
      seriesHolder[dimension].push({
        start: segmentBound.start,
        end: segmentBound.end,
        incidents: [],
      })
    }
  }

  for (const incident of incidents) {
    const dimension = dimensionKey ? dimensionKey.split('.').reduce((a, b) => a[b], incident) : '_total'

    for (const segment of seriesHolder[dimension]) {
      if (mode === splittingModes.open) {
        if (incident.firstEventTimestamp <= segment.start && (incident.state !== 'closed' || incident.lastEventTimestamp > segment.start))
          segment.incidents.push(incident)
        //incident can be accounted for again
      }

      if (mode === splittingModes.opened) {
        if (incident.firstEventTimestamp >= segment.start && incident.firstEventTimestamp < segment.end) {
          segment.incidents.push(incident)
          break //incident was accounted for, make sure it is not accounted for again
        }
      }

      if (mode === splittingModes.closed) {
        if (incident.state === 'closed' && incident.lastEventTimestamp >= segment.start && incident.lastEventTimestamp < segment.end) {
          segment.incidents.push(incident)
          break //incident was accounted for, make sure it is not accounted for again
        }
      }
    }
  }
  const series = []
  for (const [key, value] of Object.entries(seriesHolder)) {
    const seriesElement = { data: value.map(segment => segment.incidents.length) }
    if (key === '_total') seriesElement.name = t('incidents.total')
    else if (dimensionNamingDictionary) seriesElement.text = dimensionNamingDictionary[key]
    series.push(seriesElement)
  }


  return { segmentBounds, series }
}


export { splittingModes, splitIncidents }
