<script setup>

import DateTimePicker from '@/components/DateTimePicker.vue';
import { DateTime } from 'luxon';
import { useDisplay } from 'vuetify';

const { mobile } = useDisplay()
const props = defineProps({
  modelValue: { type: DateTime, default: null },
  enableTimeJumper: { type: Boolean, default: false },
  expanded: { type: Boolean, default: false },
})
defineEmits(['update:modelValue', 'update:enableTimeJumper', 'update:expanded'])

</script>

<template>
  <v-sheet
    class="position-absolute rounded-pill"
    style="top: 16px; left:8px; "
    elevation="10"
    :max-width="mobile ? '320px' : undefined"
  >
    <v-row
      :no-gutters="true"
      :dense="true"
      class="align-center"
    >
      <v-col cols="auto">
        <v-btn
          :class="props.expanded? 'ma-3' : 'ma-1'"
          :size="!props.expanded ? 'x-large' : 'medium'"
          variant="flat"
          :icon="props.expanded ? 'mdi-chevron-left' : 'mdi-clock'"
          @click="$emit('update:expanded', !props.expanded)"
        />
      </v-col>

      <v-slide-x-transition>
        <v-col
          v-if="mobile && expanded"
          :cols="8"
        >
          <v-row
            :dense="true"
            no-gutters
            class="align-center"
          >
            <v-col
              cols="auto"
            >
              <DateTimePicker
                :model-value="props.modelValue"
                :hide-details="true"
                @update:model-value="$emit('update:modelValue', $event)"
              />
            </v-col>
            <v-col
              v-if="props.enableTimeJumper === false"
              :cols="8"
            >
              <v-btn
                class="rounded mb-2"
                size="small"
                :text="$t('canvas.show_time_jumper')"
                @click="$emit('update:enableTimeJumper', !props.enableTimeJumper)"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-slide-x-transition>


      <v-slide-x-transition v-if="!mobile">
        <v-col
          v-if="expanded"
          cols="auto"
        >
          <DateTimePicker
            :model-value="props.modelValue"
            :hide-details="true"
            @update:model-value="$emit('update:modelValue', $event)"
          />
        </v-col>
      </v-slide-x-transition>
      <v-slide-x-transition v-if="!mobile">
        <v-col
          v-if="expanded && props.enableTimeJumper === false"
          cols="auto"
        >
          <v-btn
            class="mx-2 rounded-e-pill"
            :stacked="mobile === false"
            size="small"
            variant="outlined"
            :text="$t('canvas.show_time_jumper')"
            @click="$emit('update:enableTimeJumper', !props.enableTimeJumper)"
          />
        </v-col>
      </v-slide-x-transition>
    </v-row>
  </v-sheet>
</template>

<style scoped>

</style>
