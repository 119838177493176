<script setup>
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import AddFilterForm from '@/components/AddFilterForm.vue'
import { useRoute, useRouter } from 'vue-router'
import { useFilter } from '@/composables/filters/filters'
import { ALL, ANY, CONTAINS, IN, NIN } from '@/composables/filters/operators'
import { useCamerasStore } from '@/store/cameras'
import { useCameraName } from '@/composables/cameraHelpers'
import { useI18n } from 'vue-i18n'
import { humanReadableTimestamp } from '@/composables/datetime'
import JsonEditorVue from 'json-editor-vue'
import 'vanilla-jsoneditor/themes/jse-theme-dark.css'
import { useSettingsStore } from '@/store/settings'
import { useDisplay } from 'vuetify'
import OutdatedAlert from '@/components/OutdatedAlert.vue'
import equal from 'fast-deep-equal'
import AppliedFiltersList from '@/components/AppliedFiltersList.vue'

const settingsStore = useSettingsStore()
const { t } = useI18n()
const router = useRouter()
const route = useRoute()
const { width } = useDisplay()

const props = defineProps({
  queryType: { type: String, default: '' },
  queryFunction: {
    type: Function,
    default: () => async () => {},
  },
  presetFilters: { type: Array, default: () => [], required: false },
  presetItemsPerPage: { type: Number, default: 5 },
  presetOrder: { type: Object, default: null },
  readOnly: { type: Boolean, default: false },
  hideFilters: { type: Boolean, default: false },
  hideSortings: { type: Boolean, default: false },
  hideItemsPerPage: { type: Boolean, default: false },
  autoLoad: { type: Boolean, default: false },
  enableQueryParams: { type: Boolean, default: true },
  showDisplaySettings: { type: Boolean, default: false },
  uniqueDocumentIdentifier: { type: String, required: false, default: '' },
  reloadSpecificDocumentFunction: {
    type: Function,
    required: false,
    default: () => async () => {},
  },
})
defineExpose({ reloadSpecificDocument, reload, parseQueryParams })

const appliedFilters = ref([])
const newFilterDialog = ref(false)
const documents = ref(null)
const resetPagination = ref(false)
const currentPage = ref(1)
const moreDocumentsAvailable = ref(false)
const loading = ref(false)
const showGoToTopButton = ref(false)
const showCustomQueryDialog = ref(false)
const orders = [
  { value: 'asc', title: t('general_interface.ordering.ascending') },
  { value: 'desc', title: t('general_interface.ordering.descending') },
]
const initialQuery = ref({
  query: props.presetFilters,
  limit: props.presetItemsPerPage,
  order: props.presetOrder,
})
const customQuery = ref({ ...initialQuery.value })

const isBelow1100px = computed(() => width.value < 1100)
const isBelow1360px = computed(() => width.value < 1360)

const initialLoaded = computed(() => !!documents.value)

const queryIsOutdated = computed(() => {
  if (!initialLoaded.value) return false
  if (newFilterDialog.value || showCustomQueryDialog.value) return false
  return !equal(customQuery.value, initialQuery.value)
})

const itemsPerPage = computed({
  get: () => customQuery.value.limit,
  set: value => {
    customQuery.value = {
      ...customQuery.value,
      limit: value,
    }
  },
})

const sortBy = computed({
  get: () => Object.keys(customQuery.value.order)[0],
  set: value => {
    customQuery.value = {
      ...customQuery.value,
      order: { [value]: orderDirection.value },
    }
  },
})

const orderDirection = computed({
  get: () => Object.values(customQuery.value.order)[0],
  set: value => {
    customQuery.value = {
      ...customQuery.value,
      order: { [sortBy.value]: value },
    }
  },
})

const filter = computed(() => {
  return useFilter(props.queryType)
})

const sortableProperties = computed(() => {
  return filter.value.getSortableProperties()
})

const noDocumentsFound = computed(() => {
  return (
    !loading.value &&
    (!documents.value || !documents.value.length) &&
    initialLoaded.value
  )
})

function removeFilter(index) {
  appliedFilters.value.splice(index, 1)

  const query = updateQueryParams(appliedFilters.value, sortBy.value)
  router.replace({ query })

  customQuery.value = updateCustomQuery(
    appliedFilters.value,
    itemsPerPage.value,
    sortBy.value,
    orderDirection.value,
  )
}

async function addFilter(filter) {
  customQuery.value = {
    query: [...customQuery.value.query, filter],
    limit: customQuery.value.limit,
    order: {
      [sortBy.value]: orderDirection.value,
    },
  }

  appliedFilters.value.push(hydrateFilter(filter))

  const query = updateQueryParams(customQuery.value.query, sortBy.value)
  router.replace({ query })

  newFilterDialog.value = false
}

/**
 * Updates the query parameters of the current URL.
 *
 * @param {Array} filters - The filters to be updated.
 * @param {string} order - The order to be updated.
 *
 * @returns {Object} - The updated query parameters.
 */
function updateQueryParams(filters, order) {
  if (!props.enableQueryParams) return

  const filterList = encodeURIComponent(
    JSON.stringify(
      filters.map(filter => ({
        key: filter.key,
        operator: filter.operator,
        value: filter.value,
      })),
    ),
  )

  const orderBy =
    order &&
    encodeURIComponent(JSON.stringify({ [order]: orderDirection.value }))

  return { filters: filterList, order: orderBy }
}

/**
 * Updates the custom query.
 *
 * @param {Array} filters - The filters to be updated.
 * @param {number} limit - The limit to be updated.
 * @param {string} sortBy - The sortBy to be updated.
 * @param {string} orderDirection - The orderDirection to be updated.
 *
 * @returns {Object} - The updated custom query.
 */
function updateCustomQuery(filters, limit, orderBy, orderDirection) {
  return {
    query: filters.map(serializeFilter),
    limit: limit,
    order: { [orderBy]: orderDirection },
  }
}

/**
 * Saves custom query data from the JSON query editor.
 *
 * @param {string} query - The custom query data.
 * @returns {void}
 */
function saveCustomQuery(query) {
  if (!query) {
    showCustomQueryDialog.value = false
    return 
  }

  const queryObject = JSON.parse(JSON.stringify(query))
  if (!queryObject) {
    return
  }

  appliedFilters.value = queryObject.query?.map(filter =>
    hydrateFilter(filter),
  )

  customQuery.value = queryObject
  showCustomQueryDialog.value = false
}

function hydrateFilter(filter) {
  const filterConfig = useFilter(props.queryType)
  const propertyTitle = filterConfig.getPropertyTitle(filter.key)
  const dataType = filterConfig.getDataType(filter.key)
  const isArrayOperator = [NIN, IN, CONTAINS, ALL, ANY].includes(filter.operator)
  let valueText = ''
  if (dataType === 'camera') {
    if (!isArrayOperator) {
      valueText = useCameraName(filter.value)
    } else {
      const cameraNames = []
      for (const cameraId of filter.value) {
        cameraNames.push(useCameraName(cameraId))
      }
      valueText = cameraNames.join(', ')
    }
  }
  if (dataType === 'String') valueText = filter.value
  if (dataType === 'Number') valueText = filter.value
  if (dataType === 'generic') valueText = filter.value
  if (dataType === 'timestamp') valueText = humanReadableTimestamp(filter.value)
  if (dataType === 'autocomplete') {
    if (!isArrayOperator) {
      valueText = filterConfig.getOptionTitle(filter.key, filter.value)
    } else {
      const optionTitles = []
      for (const optionId of filter.value) {
        optionTitles.push(filterConfig.getOptionTitle(filter.key, optionId))
      }
      valueText = optionTitles.join(', ')
    }
  }
  const text = `${propertyTitle} ${filter.operator} ${valueText}`

  return { ...filter, text: text }
}

function serializeFilter(filter) {
  return { key: filter.key, operator: filter.operator, value: filter.value }
}

async function rehydrateFilters(dehydratedFilters) {
  const rehydratedFilters = []
  for (let dehydratedFilter of dehydratedFilters) {
    rehydratedFilters.push(hydrateFilter(dehydratedFilter))
  }
  return rehydratedFilters
}

async function parseQueryParams() {
  let filters = []
  props.presetFilters.forEach(presetFilter => {
    filters.push(presetFilter)
  })

  if (props.presetOrder) {
    sortBy.value = props.presetOrder.sortBy
    orderDirection.value = props.presetOrder.direction
  }

  if (props.enableQueryParams) {
    const queryParams = route.query

    if (queryParams.filters) {
      const decodedString = decodeURIComponent(queryParams.filters)
      JSON.parse(decodedString).forEach(filter => {
        filters.push(filter)
      })
    }

    if (queryParams.order) {
      const decodedString = decodeURIComponent(queryParams.order)
      const parsed = JSON.parse(decodedString)
      if (Object.entries(parsed).length >= 1) {
        sortBy.value = Object.entries(parsed)[0][0]
        orderDirection.value = Object.entries(parsed)[0][1]
      }
    }
  }

  return await rehydrateFilters(filters)
}

async function load({ startAfter = undefined, endBefore = undefined } = {}) {
  loading.value = true
  initialQuery.value = customQuery.value

  if (resetPagination.value) {
    currentPage.value = 1
  }

  const payload = {
    ...customQuery.value,
    limit: customQuery.value.limit + 1,
    startAfter: startAfter,
    endBefore: endBefore,
  }

  resetPagination.value = false
  const rawDocuments = await props.queryFunction(payload)

  /*
  * When the "Previous Page" button is pressed, 
  * we query all documents up to (but not including) the endBefore document. 
  * 
  * Although we request itemsPerPage.value + 1 documents, 
  * the query stops at the endBefore document, 
  * effectively fetching only itemsPerPage.value documents.
  * 
  * To ensure the pagination & the "Next Page" button is displayed, 
  * we manually append the endBefore document to the rawDocuments array, 
  * indicating that more documents are available.
  */
  if(endBefore) rawDocuments.push(endBefore)

  moreDocumentsAvailable.value =
    rawDocuments && rawDocuments.length === customQuery.value.limit + 1
    
  documents.value = moreDocumentsAvailable.value
    ? rawDocuments.slice(0, -1)
    : rawDocuments
    
  loading.value = false
}

async function reload() {
  await load()
}

async function reloadSpecificDocument(documentId) {
  if (!props.reloadSpecificDocumentFunction) return
  if (!props.uniqueDocumentIdentifier) return
  const document = await props.reloadSpecificDocumentFunction(documentId)
  if (document) {
    const index = documents.value.findIndex(
      document => document[props.uniqueDocumentIdentifier] === documentId,
    )
    documents.value.splice(index, 1, document)
  } else {
    documents.value = documents.value.filter(
      document => document[props.uniqueDocumentIdentifier] !== documentId,
    )
  }
}

async function nextPage() {
  currentPage.value = currentPage.value + 1
  await load({ startAfter: documents.value[documents.value.length - 1] })
  goToTop()
}

async function previousPage() {
  currentPage.value = currentPage.value - 1
  await load({ endBefore: documents.value[0] })
  goToTop()
}

function handleScroll() {
  showGoToTopButton.value = window.scrollY > 100
}

function goToTop() {
  window.scrollTo(0, 0)
}

const canGoToPreviousPage = computed(() => {
  if (currentPage.value === 1) return false
  if (!documents.value || documents.value.length === 0) return false
  return true
})

const canGoToNextPage = computed(() => {
  if (!documents.value || documents.value.length === 0) return false
  return moreDocumentsAvailable.value
})

/**
 * Watches for changes in the custom query and updates the URL query parameters.
 *
 * @param {object} customQuery - The custom query to watch.
 * @param {number} itemsPerPage - The items per page to watch.
 * @param {string} sortBy - The sortBy to watch.
 * @param {string} orderDirection - The orderDirection to watch.
 *
 * @returns {void}
 */
watch([customQuery, itemsPerPage, sortBy, orderDirection], () => {
  if (!initialLoaded.value) return
  if (newFilterDialog.value || showCustomQueryDialog.value) return

  const query = updateQueryParams(customQuery.value.query, sortBy.value)
  router.replace({ query })
})

onMounted(async () => {
  await useCamerasStore().keepCamerasLoaded()
  if (props.presetItemsPerPage) itemsPerPage.value = props.presetItemsPerPage
  appliedFilters.value = await parseQueryParams()

  const query = updateQueryParams(appliedFilters.value, sortBy.value)
  router.replace({ query })

  const initialQueryData = updateCustomQuery(
    appliedFilters.value,
    itemsPerPage.value,
    sortBy.value,
    orderDirection.value,
  )
  customQuery.value = initialQueryData
  initialQuery.value = initialQueryData

  window.addEventListener('scroll', handleScroll)

  if (props.autoLoad) {
    resetPagination.value = true
    return load()
  }
})

onBeforeUnmount(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>

<template>
  <div
    style="width: 100%; max-width: 2000px; margin: 0 auto;"
  >
    <v-container
      :fluid="true"
      class="mx-0 ma-0 pt-0 px-0 pb-16"
    >
      <!-- title & edit button -->
      <div 
        v-if="!hideFilters"
        class="d-flex flex-row justify-space-between align-center px-4 pt-2"
      >
        <v-card-title class="pb-0">
          {{ $t("general_interface.filter.title") }}
        </v-card-title>
        <div>
          <v-btn
            class="pa-0"
            variant="text"
            icon
            size="small"
          >
            <v-icon icon="mdi-code-json" />
            <v-dialog
              v-model="showCustomQueryDialog"
              activator="parent"
              max-width="1000px"
            >
              <v-layout-card>
                <v-card-title>
                  {{ $t("general_interface.filter.custom_query") }}
                </v-card-title>
                <v-card-text>
                  <JsonEditorVue
                    v-model="customQuery"
                    mode="text"
                    :stringified="false"
                    :class="
                      settingsStore.theme === 'dark' ? 'jse-theme-dark' : ''
                    "
                  />
                </v-card-text>
                <v-card-actions class="justify-end">
                  <v-btn
                    variant="outlined"
                    color="error"
                    class="rounded-pill"
                    @click="showCustomQueryDialog = false"
                  >
                    {{ $t("general_interface.buttons.cancel") }}
                  </v-btn>

                  <v-btn
                    variant="flat"
                    color="success"
                    class="rounded-pill"
                    @click="saveCustomQuery(customQuery)"
                  >
                    {{ $t("general_interface.buttons.confirm") }}
                  </v-btn>
                </v-card-actions>
              </v-layout-card>
            </v-dialog>
          </v-btn>
        </div>
      </div>

      <v-layout-card
        v-if="!hideFilters"
        :loading="loading"
        :disabled="loading"
      >
        <v-card-text>
          <div
            class="d-flex flex-column ga-4 mb-0"
          >
            <div
              :class="
                isBelow1100px
                  ? 'd-flex flex-column ga-10'
                  : 'd-flex flex-row align-center justify-space-between ga-2'
              "
            >
              <!-- add filter button -->
              <div
                :class="
                  isBelow1100px
                    ? 'd-flex flex-column ga-4'
                    : 'flex-fill  d-flex flex-row justify-end flex-wrap ga-4'
                "
                :style="isBelow1100px ? 'max-width: 100%' : 'max-width: 200px'"
              >
                <v-btn
                  color="success"
                  append-icon="mdi-plus"
                  size="large"
                  width="100%"
                >
                  {{ $t("general_interface.filter.add_filter") }}
                  <v-dialog
                    v-model="newFilterDialog"
                    activator="parent"
                    max-width="1000px"
                  >
                    <AddFilterForm
                      :filter-type="queryType"
                      @confirm="addFilter"
                      @cancel="newFilterDialog = false"
                    />
                  </v-dialog>
                </v-btn>
              </div>
              

              <v-divider
                v-if="!isBelow1100px && !hideItemsPerPage"
                class="mx-2"
                vertical
              />
              
              <!-- limit -->
              <div
                v-if="!hideItemsPerPage"
                :style="isBelow1100px ? 'width:100%' : 'min-width: 160px'"
              >
                <v-select
                  v-model="itemsPerPage"
                  variant="outlined"
                  density="comfortable"
                  :hide-details="true"
                  :label="$t('general_interface.filter.items_per_page')"
                  :items="[5, 10, 20, 50]"
                />
              </div>

              <v-divider
                v-if="!isBelow1100px && !hideSortings"
                class="mx-2"
                vertical
              />

              <!-- order by & order direction -->
              <div
                v-if="!hideSortings"
                :class="
                  isBelow1100px
                    ? 'd-flex flex-column ga-4' :
                      isBelow1360px ? 'd-flex flex-column ga-4'
                      : 'd-flex flex-row justify-center ga-4 column'
                "
              >
                <div
                  :style="isBelow1100px ? 'width:100%' : 'min-width: 240px'"
                >
                  <v-select
                    v-model="sortBy"
                    variant="outlined"
                    density="comfortable"
                    item-value="id"
                    item-title="title"
                    :hide-details="true"
                    :label="$t('general_interface.ordering.sort_by')"
                    :items="sortableProperties"
                    :clearable="false"
                  />
                </div>
                <div
                  :style="isBelow1100px ? 'width:100%' : 'min-width: 240px'"
                >
                  <v-select
                    v-model="orderDirection"
                    variant="outlined"
                    density="comfortable"
                    :hide-details="true"
                    :label="$t('general_interface.filter.order')"
                    :items="orders"
                  />
                </div>
              </div>

              <v-divider
                v-if="!isBelow1100px"
                class="mx-2"
                vertical
              />

              <!-- load button -->
              <div
                class="flex-fill d-flex justify-center"
                :style="isBelow1100px ? 'max-width: 100%' : 'max-width: 200px'"
              >
                <v-btn
                  color="primary"
                  variant="elevated"
                  size="large"
                  width="100%"
                  @click="
                    () => {
                      resetPagination = true
                      return load()
                    }
                  "
                >
                  {{ $t("general_interface.buttons.load") }}
                </v-btn>
              </div>
            </div>
          </div>
        </v-card-text>
      </v-layout-card>

      <!-- applied filters chip list -->
      <div
        v-if="appliedFilters.length > 0 && !hideFilters"
        class="py-4 px-8"
      >
        <p class="text-subtitle-1 mb-1">
          {{ $t("general_interface.filter.applied_filters") }}
        </p>
        <AppliedFiltersList
          :applied-filters="appliedFilters"
          :remove-filter="removeFilter"
        />
      </div>

      <v-layout-card v-if="showDisplaySettings && !loading && documents">
        <v-card-title>
          {{ $t("general_interface.display_settings") }}
        </v-card-title>
        <v-card-text>
          <slot name="displaySettings" />
        </v-card-text>
      </v-layout-card>

      <slot
        v-if="!loading && documents && documents.length > 0"
        name="list"
        :documents="documents"
        :reload="load"
      />
      <OutdatedAlert
        v-if="queryIsOutdated"
        @click="
          goToTop();
          resetPagination = true;
          load();
        "
      />

      <v-fade-transition>
        <v-btn
          v-if="showGoToTopButton"
          elevation="5"
          color="primary"
          icon="mdi-arrow-up"
          style="position: fixed; bottom: 24px; right: 24px"
          @click="goToTop"
        />
      </v-fade-transition>

      <v-container
        v-if="loading"
        class="ma-0 pa-0 w-100"
        :fluid="true"
      >
        <v-skeleton-loader
          v-for="index in itemsPerPage"
          :key="index"
          type="article, actions"
          class="ma-2"
        />
      </v-container>

      <v-row
        v-if="noDocumentsFound"
        class="justify-center my-4 mx-1"
      >
        <v-alert
          type="error"
          max-width="500px"
        >
          {{ $t("general_interface.filter.nothing_found_text") }}
        </v-alert>
      </v-row>

      <v-row
        v-if="
          !queryIsOutdated &&
            !resetPagination &&
            !noDocumentsFound &&
            (canGoToNextPage || canGoToPreviousPage)
        "
        class="justify-center align-center my-4"
      >
        <v-btn
          prepend-icon="mdi-arrow-left"
          class="ma-2 rounded-pill"
          :disabled="!canGoToPreviousPage || loading"
          @click="previousPage"
        >
          {{ $t("general_interface.pagination.previous") }}
        </v-btn>
        <v-chip
          class="ma-2"
          variant="flat"
          size="large"
        >
          {{ $t("general_interface.pagination.page", currentPage) }}
        </v-chip>
        <v-btn
          append-icon="mdi-arrow-right"
          class="ma-2 rounded-pill"
          :disabled="!canGoToNextPage || loading"
          @click="nextPage"
        >
          {{ $t("general_interface.pagination.next") }}
        </v-btn>
      </v-row>
    </v-container>
  </div>
</template>
