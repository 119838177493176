import Syncable from "@/syncing/Syncable.js";
import {canViewRegionsHelper} from "@/composables/permissionHelper.js";
import backendHandler from "@/BackendAPI.js";
import {useRegionStore} from "@/store/regions.js";
import {useInstanceStore} from "@/store/backendInstances.js";

class RegionsSyncable extends Syncable {
  constructor(globalUniqueIdentifier) {
    super(globalUniqueIdentifier, 1000 * 60 * 30, RegionsSyncable.getSyncableHandle(), true, true);
  }

  satisfiesPreconditions() {
    if(useInstanceStore().instances[this.globalUniqueIdentifier].instanceType !== "barn") return false
    if (!super.satisfiesPreconditions()) return false;
    return canViewRegionsHelper(this.globalUniqueIdentifier)
  }

  hasInitialData() {
    return !!useRegionStore().getRegionTypes(this.globalUniqueIdentifier)
  }

  async workload() {
    await useRegionStore().loadRegionSetup(this.globalUniqueIdentifier)
  }
  static getSyncableHandle() {
    return "regions"
  }
}

export default RegionsSyncable
