import { useLogsStore } from '@/store/logs.js';

class SyncableQueue {
  constructor() {
    this.jobs = {}
    this.mainInterval = null;
    this.startMainInterval();
    this.inactivityDetector = null;
    try {
      if (window)
        this.startInactivityDetector();
    } catch {
    }

    this.inactive = false
  }

  executeJobsForGlobalUniqueIdentifier(globalUniqueIdentifier) {
    if (!this.jobs[globalUniqueIdentifier]) return;
    for (const handle of Object.keys(this.jobs[globalUniqueIdentifier])) {
      const job = this.jobs[globalUniqueIdentifier][handle];
      job.sync().catch(e => {
        useLogsStore().addLogEntry({
          message: 'Job ' + job.handle + ' failed',
          error: e,
          tag: 'syncableQueue',
          globalUniqueIdentifier,
          level: 'ERROR',
        })
      },
      )
    }
  }

  executeJobs() {
    if (this.inactive) return;
    for (const guid of Object.keys(this.jobs)) {
      this.executeJobsForGlobalUniqueIdentifier(guid)
    }
  }

  markJobsAsInactive() {
    for (const guid of Object.keys(this.jobs)) {
      for (const handle of Object.keys(this.jobs[guid])) {
        const job = this.jobs[guid][handle];
        job.markAsInactive()
      }
    }
  }

  markJobsAsActive() {
    for (const guid of Object.keys(this.jobs)) {
      for (const handle of Object.keys(this.jobs[guid])) {
        const job = this.jobs[guid][handle];
        job.markAsActive()
      }
    }
  }

  startMainInterval() {
    this.mainInterval = setInterval(() => {
      this.executeJobs()
    }, 1000 * 5 + Math.floor(Math.random() * 3000))
  }

  addSyncable(syncable, immediate = true) {
    if (!this.jobs[syncable.globalUniqueIdentifier]) this.jobs[syncable.globalUniqueIdentifier] = {};
    if (!this.jobs[syncable.globalUniqueIdentifier][syncable.handle]) {
      this.jobs[syncable.globalUniqueIdentifier][syncable.handle] = syncable;
    }
    if (immediate) {
      this.jobs[syncable.globalUniqueIdentifier][syncable.handle].sync().catch(() => {})
    }

  }

  doesSyncableExist(globalUniqueIdentifier, handle) {
    return this.jobs[globalUniqueIdentifier] && this.jobs[globalUniqueIdentifier][handle]
  }

  removeSyncable(globalUniqueIdentifier, handle) {
    if (!this.jobs[globalUniqueIdentifier]) return;
    delete this.jobs[globalUniqueIdentifier][handle];
  }

  async ensureSyncable(globalUniqueIdentifier, handle) {
    if (!this.jobs[globalUniqueIdentifier]) this.jobs[globalUniqueIdentifier] = {};
    const syncable = this.jobs[globalUniqueIdentifier][handle]
    if (!syncable) {
      throw new Error('Syncable with handle ' + handle + ' for instance ' + globalUniqueIdentifier + ' does not exist')
    }
    return syncable.ensureInitialDataExists()
  }

  resetInactivityDetector() {
    try {
      if (this.inactive) {
        this.inactive = false;
        this.markJobsAsActive()
        this.executeJobs()
      }
      if (this.inactivityDetector) clearTimeout(this.inactivityDetector)
    } catch (e) {
      useLogsStore().addLogEntry({ message: 'Error while resetting inactivity detector', error: e, tag: 'syncableQueue', level: 'ERROR', globalUniqueIdentifier: null })
    }
    this.inactivityDetector = setTimeout(() => {
      useLogsStore().addLogEntry({ message: 'Inactivity detected, stopping syncable jobs', tag: 'syncableQueue', level: 'INFO', globalUniqueIdentifier: null })
      this.inactive = true;
      this.markJobsAsInactive()
    }, 1000 * 60 * 30)

  }

  startInactivityDetector() {
    this.resetInactivityDetector()
    window.onload = this.resetInactivityDetector.bind(this);
    window.onmousemove = this.resetInactivityDetector.bind(this);
    window.onmousedown = this.resetInactivityDetector.bind(this);
    window.ontouchstart = this.resetInactivityDetector.bind(this);
    window.onclick = this.resetInactivityDetector.bind(this);
    window.onkeypress = this.resetInactivityDetector.bind(this);
  }
}

export default new SyncableQueue()
