<script setup>
import { useIncidentStore } from '@/store/incident';
import { computed, ref } from 'vue';
import WYSIWYG from '@/components/WYSIWYG.vue';

const incidentStore = useIncidentStore()

const props = defineProps({
  incidentId: { type: String, default: null },
  incidentEventId: { type: String, default: null },
})
const emit = defineEmits(['commentCreated'])

const commentText = ref(null)

const sendButtonDisabled = computed(() => {
  if(commentText.value === null) return true
  if(commentText.value.length === 0) return true
  if(commentText.value.replaceAll(' ','') === '<p></p>') return true

  return false
})
async function sendComment() {
  await incidentStore.createNewIncidentComment(commentText.value, props.incidentId, props.incidentEventId)
  emit('commentCreated')
  commentText.value = ''
}

</script>

<template>
  <WYSIWYG v-model="commentText" />
  <v-row class="justify-end mx-1 ma-2">
    <v-btn
      color="primary"
      variant="outlined"
      :disabled="sendButtonDisabled"
      class="rounded-pill"
      @click="sendComment"
    >
      {{ $t("general_interface.buttons.save") }}
    </v-btn>
  </v-row>
</template>

<style scoped>

</style>
