import i18n from '../../plugins/i18n'
const { t } = i18n.global
import { humanReadableDuration, humanReadableTimestamp } from '@/composables/datetime';



function buildDataTableEntry(incident) {
  const headers = []
  const item = {}
  item.id = incident.incidentId
  item.isIncident = true;

  headers.push({ id: 'incidentType', title: 'Incident Type', key: 'incidentType' })
  item.incidentType = t(`incidents.${incident.type}.title`)

  switch(incident.type) {
  case 'barnIntervention': {

    headers.push({ id: 'timestamp', title: t('incidentList.headers.timestamp'), key: 'timestamp' })
    item.timestamp = humanReadableTimestamp(incident.firstEventTimestamp)

    headers.push({ id: 'lastUpdatedTimestamp', title: t('incidentList.headers.lastUpdatedTimestamp'), key: 'lastUpdatedTimestamp' })
    item.lastUpdatedTimestamp = humanReadableTimestamp(incident.lastEventTimestamp)
    headers.push({ id: 'severity', title: t('incidentList.headers.severity'), key: 'severity' })
    item.severity = t(`incidents.barnIntervention.severities.${incident.messagePayload.severity}`)

    headers.push({ id: 'title', title: t('incidentList.headers.title'), key: 'title' })
    item.title = incident.messagePayload.title

    headers.push({ id: 'description', title: t('incidentList.headers.description'), key: 'description', wysiwyg: true })
    item.description = incident.messagePayload.description

    headers.push({ id: 'state', title: t('incidentList.headers.state'), key: 'state' })
    item.state = t(`incidents.barnIntervention.status.${incident.state}`)

    headers.push({ id: 'type', title: t('incidentList.headers.type'), key: 'type' })
    item.type = t(`incidents.barnIntervention.types.${incident.messagePayload.type}`)

    if(incident.messagePayload?.issue) {
      headers.push({ id: 'issue', title: t('incidentList.headers.issue'), key: 'issue' })
      item.issue = t(`incidents.barnIntervention.issues.${incident.messagePayload.issue}`)
    }

    headers.push({ id: 'triggeredBy', title: t('incidentList.headers.triggered_by'), key: 'triggeredBy' })
    if(incident.triggeredBy.entityType === 'account') item.triggeredBy = incident.triggeredBy.displayName
    else item.triggeredBy = t('incidentList.triggered_automatically')
    break;
  }
  case 'deadAnimal': {
    headers.push({ id: 'state', title: t('incidentList.headers.state'), key: 'state' })
    item.state = t(`incidents.deadAnimal.status.${incident.state}`)

    headers.push({ id: 'timestamp', title: t('incidentList.headers.timestamp'), key: 'timestamp' })
    item.timestamp = humanReadableTimestamp(incident.firstEventTimestamp)

    headers.push({ id: 'duration', title: t('incidentList.headers.duration'), key: 'duration' })
    item.duration = incident.state === 'closed' ? humanReadableDuration(incident.lastEventTimestamp - incident.firstEventTimestamp): humanReadableDuration(Date.now() - incident.firstEventTimestamp)

    headers.push({ id: 'camera', title: t('incidentList.headers.camera'), key: 'camera' })
    item.camera = incident.messagePayload.cameraName

    headers.push({ id: 'triggeredBy', title: t('incidentList.headers.triggered_by'), key: 'triggeredBy' })
    if(incident.triggeredBy.entityType === 'account') item.triggeredBy = incident.triggeredBy.displayName
    else item.triggeredBy = t('incidentList.triggered_automatically')
    break;
  }

  default: {
    headers.push({ id: 'state', title: t('incidentList.headers.state'), key: 'state' })
    item.state = incident.state

    headers.push({ id: 'timestamp', title: t('incidentList.headers.timestamp'), key: 'timestamp' })
    item.timestamp = humanReadableTimestamp(incident.firstEventTimestamp)

    headers.push({ id: 'duration', title: t('incidentList.headers.duration'), key: 'duration' })
    item.duration = incident.state === 'closed' ? humanReadableDuration(incident.lastEventTimestamp - incident.firstEventTimestamp): humanReadableDuration(Date.now() - incident.firstEventTimestamp)

    if(incident.messagePayload?.cameraName) {
      headers.push({ id: 'camera', title: t('incidentList.headers.camera'), key: 'camera' })
      item.camera = incident.messagePayload.cameraName
    }


    headers.push({ id: 'triggeredBy', title: t('incidentList.headers.triggered_by'), key: 'triggeredBy' })
    if(incident.triggeredBy.entityType === 'account') item.triggeredBy = incident.triggeredBy.displayName
    else item.triggeredBy = t('incidentList.triggered_automatically')

    break;
  }
  }
  return { headers, item }
}

function buildItemForIncidentEvent(incidentEvent, incident) {
  const item = {}
  item.id = incidentEvent.incidentEventId
  item.isIncident = false;

  item.incidentType = t(`incidents.${incident.type}.title`)

  switch(incident.type) {
  case 'barnIntervention': {
    item.timestamp = humanReadableTimestamp(incidentEvent.eventTimestamp)

    if(incidentEvent.payload)
    {
      item.severity = t(`incidents.barnIntervention.severities.${incidentEvent.payload.severity}`)
      item.title = incidentEvent.payload.title
      item.description = incidentEvent.payload.description
      item.type = t(`incidents.barnIntervention.types.${incidentEvent.payload.type}`)


      if(incidentEvent.payload.issue) {
        item.issue = t(`incidents.barnIntervention.issues.${incidentEvent.payload.issue}`)
      }
    }

    item.state = t(`incidents.barnIntervention.status.${incidentEvent.state}`)



    if(incidentEvent.triggeredBy.entityType === 'account') item.triggeredBy = incidentEvent.triggeredBy.displayName
    else item.triggeredBy = t('incidentList.triggered_automatically')
    break;
  }
  case 'deadAnimal': {
    item.state = t(`incidents.deadAnimal.status.${incidentEvent.state}`)

    item.timestamp = humanReadableTimestamp(incidentEvent.eventTimestamp)

    if(incidentEvent.triggeredBy.entityType === 'account') item.triggeredBy = incidentEvent.triggeredBy.displayName
    else item.triggeredBy = t('incidentList.triggered_automatically')
    break;
  }

  default: {
    item.state = incidentEvent.state

    item.timestamp = humanReadableTimestamp(incidentEvent.eventTimestamp)

    if(incidentEvent.triggeredBy.entityType === 'account') item.triggeredBy = incidentEvent.triggeredBy.displayName
    else item.triggeredBy = t('incidentList.triggered_automatically')

    break;
  }
  }
  return item
}


function buildDataTable(incidents) {
  const headers = []
  const items = []
  for (let incident of incidents) {
    const headersAndItem = buildDataTableEntry(incident)
    const incidentHeaders = headersAndItem.headers
    const item = headersAndItem.item
    for (let header of incidentHeaders) {
      if (!headers.find(h => h.id === header.id)) {
        headers.push(header)
      }
    }
    items.push(item)
  }
  return { headers, items }
}




export { buildDataTable, buildItemForIncidentEvent }
