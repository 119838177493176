<script setup>
import { computed, onMounted, ref } from 'vue';
import {
  humanReadableTimestamp,
} from '@/composables/datetime.js';
import { useIncidentStore } from '@/store/incident.js';

const incidentStore = useIncidentStore();
const loading = ref(true);
const loaded = ref(false);
const incident = ref({});
const penState = ref(null);

const color = computed(() => {
  if (penState.value?.state === 'penFull') return 'green';
  if (penState.value?.state === 'penEmpty') return 'blue';
  return 'red';
});

onMounted(async () => {
  incident.value = await incidentStore.loadLatestPenState();
  penState.value = await incidentStore.loadPenStateDuration(incident.value);

  loading.value = false;
  loaded.value = !!incident.value;
});
</script>

<template>
  <v-layout-card
    v-if="loaded"
    :loading="loading"
  >
    <v-row
      class="float-end ma-0"
      :dense="true"
      :no-gutters="true"
    >
      <v-col cols="auto">
        <slot name="commentButton" />
      </v-col>
      <v-col cols="auto">
        <slot name="assignButton" />
      </v-col>
    </v-row>
    <v-card-title>{{ $t("incidents.barnState.title") }}</v-card-title>
    <v-card-text>
      <v-alert
        :color="color"
        :type="
          penState.state === 'penFull' || penState.state === 'penEmpty'
            ? 'info'
            : 'error'
        "
      >
        {{
          $t("incidents.barnState.statusTexts." + penState.state, {
            timestamp: humanReadableTimestamp(incident.lastEventTimestamp),
            day: penState.duration,
          })
        }}
      </v-alert>
    </v-card-text>
    <v-card-actions class="justify-end">
      <slot
        name="genericActions"
        v-bind="{
          enableNewEventButton: true,
          newEventButtonText: $t('incidents.barnState.actions.new-event'),
        }"
      />
    </v-card-actions>
  </v-layout-card>
</template>
<style scoped></style>
